import type { Maybe, OpenSollicitatie_Openapply } from '~/graphql/types'
import { LossePlaatjie as Image, LosseLink as Link, LosseContent as Content } from '@ubo/losse-sjedel'

export default function OpenApply({ fields }: { fields: Maybe<OpenSollicitatie_Openapply> | undefined }) {
  return (
    <div id="OpenApply" className="bg-sprout relative shadow-feed flex flex-col gap-5 lg:gap-10 pt-6 lg:pt-9 pb-24 lg:pb-10 px-5 2xl:px-14">
      <div className="flex gap-4 justify-between items-center">
        <div className="flex gap-4 2xl:gap-9">
          <div className="bg-outrageous-orange relative min-w-[90px] px-4 pb-5">
            <div className="bg-outrageous-orange absolute h-6 lg:h-9 bottom-full left-0 w-full" />
            <Image maxwidth={850} className="mx-auto" src={fields?.icon} alt="yesway-logo" />
          </div>
          <Content className="content-xs">{fields?.title}</Content>
        </div>

        <Link
          className="button-alt-outline text-lg px-6 py-3 lg:relative absolute bottom-5 lg:bottom-0 right-5 lg:right-0 whitespace-nowrap"
          to={fields?.link?.url}
        >
          {fields?.link?.title}
        </Link>
      </div>
      <div>
        <Content className="content">{fields?.description}</Content>
      </div>
    </div>
  )
}
