import React from 'react'
import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'

// Components
import BannerDefault from './BannerDefault'
import BannerImages from './BannerImages'
import BannerVideo from './BannerVideo'
import BannerFeed from './BannerFeed'
import BannerTitle from './BannerTitle'
import BannerDetail from './BannerDetail'
import BannerHome from './BannerHome'
import BannerClient from './BannerClient'
import BannerContainerized from './BannerContainerized'

const BannerShell: React.FC<LosseFlexShellProps> = ({ fields, index }) => {
  const types: LosseFlexShellType = {
    default: BannerDefault,
    video: BannerVideo,
    images: BannerImages,
    feed: BannerFeed,
    title: BannerTitle,
    detail: BannerDetail,
    home: BannerHome,
    client: BannerClient,
    containerized: BannerContainerized
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}

export default BannerShell
