import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Text } from '~/graphql/types'
import { Navigation } from 'swiper'
import Slider, { Slide } from '~/components/elements/Slider'
import { useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { LossePlaatjie } from '@ubo/losse-sjedel'

export default function TextWithSlider({ fields }: { fields: Page_Flexcontent_Flex_Text }) {
  const [isLastSlide, setLastSlide] = useState(false)

  const hasIcons = fields.slides?.some((item) => item?.icon)

  return (
    <section id="TextWithSlider" data-component="TextWithSlider" className="bg-sprout section my-10 relative overflow-hidden">
      <div className="container xl:py-10">
        <div className="grid grid-cols-1 xl:grid-cols-3 gap-8 lg:gap-10 xl:gap-16">
          <div className="flex flex-col gap-5 lg:gap-10">
            <Content className="content xl:children-headings:text-4xl text-white">{fields.title}</Content>
            <Content className="content">{fields.description}</Content>
          </div>
          <div className="col-span-1 xl:col-span-2 flex flex-col gap-4 lg:gap-7 relative">
            <div className="px-5 xl:hidden block">
              <div className="flex justify-start gap-3 md:gap-5 items-center">
                <svg
                  className="custom-swiper-prev rotate-180 w-[35px] md:w-[40px] group cursor-pointer"
                  width="52"
                  height="52"
                  viewBox="0 0 52 52"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle className="transition-all duration-200" cx="26" cy="26" r="26" fill="#FF5C39" />
                  <path d="M27.7027 25.7765L17 7H24.2973L35 25.7765L24.2973 45H17L27.7027 25.7765Z" fill="white" />
                </svg>
                <svg
                  className="custom-swiper-next group w-[35px] md:w-[40px] cursor-pointer"
                  width="52"
                  height="52"
                  viewBox="0 0 52 52"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle className="transition-all duration-200" cx="26" cy="26" r="26" fill="#FF5C39" />
                  <path d="M27.7027 25.7765L17 7H24.2973L35 25.7765L24.2973 45H17L27.7027 25.7765Z" fill="white" />
                </svg>
              </div>
            </div>
            <div className="">
              <Slider
                navigation={{
                  nextEl: '.custom-swiper-next',
                  prevEl: '.custom-swiper-prev'
                }}
                modules={[Navigation]}
                onActiveIndexChange={(swiper) => {
                  if (swiper.activeIndex + 1 >= (fields?.slides?.length || 0) - 1 && !isLastSlide) {
                    setLastSlide(true)
                  } else if (isLastSlide) {
                    setLastSlide(false)
                  }
                }}
                breakpoints={{
                  0: {
                    slidesPerView: 1.1,
                    spaceBetween: 10
                  },
                  639: {
                    slidesPerView: hasIcons ? 1.2 : 1.8,
                    spaceBetween: 10
                  },
                  1024: {
                    slidesPerView: hasIcons ? 1.4 : 2.2,
                    spaceBetween: 12
                  },
                  1279: {
                    slidesPerView: hasIcons ? 1.6 : 2.4,
                    spaceBetween: 14
                  }
                }}
              >
                {fields.slides?.map((item, index) => (
                  <Slide className="!h-auto" key={index}>
                    <div className="bg-white relative h-full p-5 lg:p-8">
                      <div className="grid grid-cols-5 gap-5">
                        {item?.icon && <LossePlaatjie src={item.icon} />}
                        {!item?.icon && (
                          <div className="flex items-center">
                            <div>
                              <div className="bg-sprout text-white font-nord font-medium text-lg md:text-2xl w-8 md:w-12 h-8 md:h-12 rounded-full flex justify-center items-center">
                                {item?.number}.
                              </div>
                            </div>
                          </div>
                        )}
                        {/* h-[64px] md:h-[84px] */}
                        <div className="col-span-4 flex items-center ">
                          <Content className="content">{item?.title}</Content>
                        </div>
                      </div>
                      <div className="mt-5">
                        <Content className="content lg:text-base">{item?.description}</Content>
                      </div>
                    </div>
                  </Slide>
                ))}
              </Slider>
            </div>
            <AnimatePresence>
              {!isLastSlide && (
                <motion.div
                  exit={{ opacity: 0 }}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  className="from-sprout bg-gradient-to-l z-20 to-transparent absolute h-full w-[100px] right-0 top-0"
                />
              )}
            </AnimatePresence>
            <div className="px-5 xl:block hidden">
              <div className="hidden lg:flex justify-start gap-5 items-center">
                <svg
                  className="custom-swiper-prev rotate-180 w-[30px] lg:w-[40px] group cursor-pointer"
                  width="52"
                  height="52"
                  viewBox="0 0 52 52"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle className="transition-all duration-200" cx="26" cy="26" r="26" fill="#FF5C39" />
                  <path d="M27.7027 25.7765L17 7H24.2973L35 25.7765L24.2973 45H17L27.7027 25.7765Z" fill="white" />
                </svg>
                <svg
                  className="custom-swiper-next group w-[30px] lg:w-[40px] cursor-pointer"
                  width="52"
                  height="52"
                  viewBox="0 0 52 52"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle className="transition-all duration-200" cx="26" cy="26" r="26" fill="#FF5C39" />
                  <path d="M27.7027 25.7765L17 7H24.2973L35 25.7765L24.2973 45H17L27.7027 25.7765Z" fill="white" />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

// import Content from '~/components/elements/Content'
// import type { Page_Flexcontent_Flex_Text } from '~/graphql/types'
// import { Swiper, SwiperSlide } from 'swiper/react'
// import { Navigation, type Swiper as SwiperType } from 'swiper'
// import { useEffect, useState } from 'react'
// import clsx from 'clsx'
// import { LossePlaatjie } from '@ubo/losse-sjedel'

// export default function TextWithSlider({ fields }: { fields: Page_Flexcontent_Flex_Text }) {
//   const [swiper, setSwiper] = useState<SwiperType>()
//   const [items, setItems] = useState<any[]>([])
//   const [mobileItems, setMobileItems] = useState<any[]>([])

//   useEffect(() => {
//     // move last item to the first position which the array is fields.slides
//     if (!fields.slides) return

//     const fake = JSON.parse(JSON.stringify(fields.slides))

//     setMobileItems(fake)

//     const lastItem = fields.slides[fields.slides?.length - 1]
//     fields.slides.pop()
//     // @ts-ignore
//     const newItems = [lastItem, ...fields.slides, lastItem, ...fields.slides]
//     setItems(newItems)
//   }, [fields.slides])

//   return (
//     <section id="TextWithSlider" data-component="TextWithSlider" className="bg-sprout section relative overflow-hidden">
//       <div className="container flex flex-col gap-6 lg:gap-16">
//         <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
//           <div>
//             <Content className="content text-white">{fields.title}</Content>
//           </div>
//           <div>
//             <Content className="content">{fields.description}</Content>
//           </div>
//         </div>
//         <div>
//           <Content className="content">{fields.subdescription}</Content>
//           <div>
//             <div className="hidden lg:flex justify-center gap-5 items-center pt-6">
//               <svg
//                 className="custom-swiper-prev rotate-180 w-[40px] lg:w-[50px] group cursor-pointer"
//                 width="52"
//                 height="52"
//                 viewBox="0 0 52 52"
//                 fill="none"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <circle className="group-hover:fill-sprout transition-all duration-200" cx="26" cy="26" r="26" fill="#FF5C39" />
//                 <path d="M27.7027 25.7765L17 7H24.2973L35 25.7765L24.2973 45H17L27.7027 25.7765Z" fill="white" />
//               </svg>
//               <svg
//                 className="custom-swiper-next group w-[40px] lg:w-[50px] cursor-pointer"
//                 width="52"
//                 height="52"
//                 viewBox="0 0 52 52"
//                 fill="none"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <circle className="group-hover:fill-sprout transition-all duration-200" cx="26" cy="26" r="26" fill="#FF5C39" />
//                 <path d="M27.7027 25.7765L17 7H24.2973L35 25.7765L24.2973 45H17L27.7027 25.7765Z" fill="white" />
//               </svg>
//             </div>
//           </div>
//         </div>
//         <div />
//       </div>
//       <div className="lg:hidden px-5 flex flex-col gap-3">
//         {mobileItems.map((item, index) => (
//           <div
//             key={index}
//             className={
//               (clsx(index === (swiper?.activeIndex || 0) + 1) && 'custom-big-slide',
//               'bg-white flex items-center relative h-full p-4 md:p-12 lg:px-12 lg:py-10')
//             }
//           >
//             <div className="grid grid-cols-5 gap-5">
//               <div className="flex items-center">
//                 {/* <div className="bg-sprout text-white font-nord font-medium text-2xl w-12 h-12 rounded-full flex justify-center items-center">
//                   {item.number}.
//                 </div> */}
//                 <LossePlaatjie src={item?.icon} alt="icon" />
//               </div>
//               <div className="col-span-4">
//                 <Content className="content children-p:font-prompt children-strong:children-p:font-bold">{item?.title}</Content>
//               </div>
//               <div className="col-span-5">
//                 <Content className="children-p:font-prompt custom-slide-description">{item?.description}</Content>
//               </div>
//             </div>
//           </div>
//         ))}
//       </div>
//       <div className="max-w-[1728px] lg:block hidden mx-auto">
//         <Swiper
//           onSlideChange={() => console.log(swiper)}
//           onSwiper={(swiper) => setSwiper(swiper)}
//           className="custom-swiper"
//           loop
//           navigation={{
//             nextEl: '.custom-swiper-next',
//             prevEl: '.custom-swiper-prev'
//           }}
//           modules={[Navigation]}
//           breakpoints={{
//             0: {
//               slidesPerView: 2,
//               spaceBetween: 0
//             },
//             1024: {
//               slidesPerView: 3,
//               spaceBetween: 0
//             }
//           }}
//         >
//           {items.map((item, index) => (
//             <SwiperSlide key={index}>
//               <div
//                 className={
//                   (clsx(index === (swiper?.activeIndex || 0) + 1) && 'custom-big-slide',
//                   'bg-white flex items-center relative h-full p-4 lg:px-12 lg:py-10')
//                 }
//               >
//                 {/* <sv
//                   className="absolute pointer-events-none h-full w-full top-0 right-0 2xl:-right-12"
//                   width="454"
//                   height="673"
//                   viewBox="0 0 454 673"
//                   fill="none"
//                   xmlns="http://www.w3.org/2000/svg"
//                 >
//                   <path d="M313.755 0H453.529L153.8 673H6.21124L313.755 0Z" fill="#222222" fillOpacity="0.05" />
//                   <path d="M130.468 0H0V406.604H130.468V0Z" fill="#222222" fillOpacity="0.05" />
//                 </svg> */}

//                 <div className="grid grid-cols-5 gap-5">
//                   <div className="flex items-center">
//                     {/* <div className="bg-sprout text-white font-nord font-medium text-2xl w-12 h-12 rounded-full flex justify-center items-center">
//                       {item.number}.
//                     </div> */}
//                     <LossePlaatjie src={item?.icon} alt="icon" />
//                   </div>
//                   <div className="col-span-4">
//                     <Content className="children-p:font-prompt md:children-strong:children-p:text-base children-strong:children-p:text-sm children-strong:children-p:font-bold">
//                       {item?.title}
//                     </Content>
//                   </div>
//                   <div className="col-span-5">
//                     <Content className="children-p:font-prompt custom-slide-description">{item?.description}</Content>
//                   </div>
//                 </div>
//               </div>
//             </SwiperSlide>
//           ))}
//         </Swiper>
//       </div>
//     </section>
//   )
// }
