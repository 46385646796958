import type { Page_Flexcontent_Flex_Cta } from '~/graphql/types'
import { LosseContent as Content, LosseLink } from '@ubo/losse-sjedel'
import { useInView } from 'react-hook-inview'
import { useEffect, useState } from 'react'

export default function Apply({ fields }: { fields: Page_Flexcontent_Flex_Cta }) {
  const [ref, isVisible] = useInView()
  const [hasBeenVisible, setHasBeenVisible] = useState(false)

  useEffect(() => {
    if (isVisible) {
      setHasBeenVisible(true)
    }
  }, [isVisible])

  return (
    <section id="Apply">
      <div className="container py-10 md:py-16 lg:py-28">
        <div className="grid grid-cols-10 lg:grid-cols-[repeat(17,_minmax(0,_1fr))]">
          <div className="col-span-10 flex flex-col lg:gap-10 gap-6 justify-center py-8 sm:py-16 px-8 sm:px-16 xl:px-32 lg:aspect-square bg-outrageous-orange">
            <Content className="content">{fields.description}</Content>
            <div className="flex justify-center lg:justify-start">
              <LosseLink src={fields.link} className="button" />
            </div>
          </div>
          <div className="col-span-10 lg:col-span-7 flex items-center min-h-[600px] lg:min-h-[612px]" ref={ref}>
            {hasBeenVisible && <Content className="children-iframe:aspect-square w-full">{fields.videoaskcode}</Content>}
          </div>
        </div>
      </div>
    </section>
  )
}
