import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Banner } from '~/graphql/types'
import { Link, LossePlaatjie as Image } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import { motion } from 'framer-motion'
import { useRef } from 'react'

export default function BannerClient({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  const element = useRef<HTMLDivElement>(null)

  return (
    <section ref={element} id="BannerClient" className="relative bg-outrageous-orange pt-5 pb-10 sm:py-10 lg:py-16 ">
      {/* <div className="bg-outrageous-orange w-full absolute top-0 left-0 h-[130px] lg:h-[200px] 2xl:h-[240px]" /> */}
      <div className="max-w-[1728px] px-3 lg:px-0 mx-auto relative flex flex-col gap-14">
        <div className="relative">
          <div className="relative flex items-center justify-center flex-wrap md:grid md:grid-cols-[repeat(17,_minmax(0,_1fr))]">
            <div className="aspect-video gap-6 p-10 md:p-20 lg:p-40 bg-white md:w-full md:col-span-10 relative flex flex-col lg:justify-center lg:items-center">
              <Content className="content-xs children-headings:pb-3">{fields.description}</Content>
              <div className="flex lg:flex-row flex-col w-full gap-3 md:gap-6 justify-start">
                {fields.links?.map((link, index) => (
                  <Link
                    key={index}
                    className={clsx(index === 0 && 'button-alt', index === 1 && 'button', '!w-[unset] px-4 text-center')}
                    to={link?.link?.url || '/'}
                  >
                    {link?.link?.title}
                  </Link>
                ))}
              </div>
            </div>
            <div className="h-[70%] md:col-span-7 relative flex items-center">
              <Image maxwidth={1200} className="object-cover w-[70%] mx-auto lg:w-full" src={fields.image} alt="banner-images-image-1" />
            </div>
          </div>

          <motion.svg
            className="cursor-pointer lg:block hidden mx-auto left-0 right-0 mt-10 mb-3"
            width="39"
            height="52"
            viewBox="0 0 39 52"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            animate={{ y: [-20, 20, -20] }}
            transition={{ loop: Infinity, duration: 4 }}
            onClick={() => {
              if (!element.current) {
                return
              }

              const top = element.current.clientHeight

              if (top - window.scrollY <= 0) {
                return
              }

              window.scrollTo({
                top,
                left: 0,
                behavior: 'smooth'
              })
            }}
          >
            <g clipPath="url(#clip0_141_34)">
              <path
                d="M19.5006 9.93723C18.1546 9.93723 17.0635 11.0448 17.0635 12.4111V17.36C17.0635 18.7263 18.1546 19.8339 19.5006 19.8339C20.8466 19.8339 21.9378 18.7263 21.9378 17.36V12.4111C21.9378 11.0448 20.8466 9.93723 19.5006 9.93723Z"
                fill="white"
              />
              <path
                d="M21.9377 0.0405933V0H17.0623V0.0405933C7.64392 0.0510316 0.0102833 7.79855 0 17.36V32.2056C0 43.1379 8.73053 52 19.5006 52C30.2706 52 39.0011 43.1379 39.0011 32.2056V17.36C38.9897 7.79855 31.3572 0.0510316 21.9377 0.0405933ZM34.1257 32.2056C34.1257 40.4042 27.5775 47.0511 19.5006 47.0511C11.4236 47.0511 4.87543 40.4042 4.87543 32.2056V17.36C4.88343 10.5311 10.3359 4.99645 17.0634 4.98833H21.9389C28.6664 4.99645 34.1189 10.5311 34.1269 17.36V32.2056H34.1257Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_141_34">
                <rect width="39" height="52" fill="white" />
              </clipPath>
            </defs>
          </motion.svg>
        </div>
      </div>
    </section>
  )
}
