/* eslint-disable react/no-array-index-key */
import React from 'react'
import { useLoaderData, LosseLink } from '@ubo/losse-sjedel'
import { motion, AnimatePresence } from 'framer-motion'
import HeaderSubMenu from './HeaderSubmenu'
import type { LoaderData } from '@ubo/losse-sjedel'
import type { Component_Header } from '~/graphql/types'
import useMediaQuery from '~/hooks/useMediaQuery'

export interface HeaderLoaderData extends Omit<LoaderData, 'header'> {
  header: Component_Header
}

const Menu: React.FC = () => {
  const { header } = useLoaderData<HeaderLoaderData>()
  const [open, setOpen] = React.useState(-1)

  // const data = useLoaderData<LoaderData<HeaderLoaderData>>()
  // @ts-ignore
  // const totalVacancies = data.rest?.vacancies?.pageInfo?.total
  // // @ts-ignore
  // const totalAssignments = data.rest?.assignments?.pageInfo?.total

  const isDesktop = useMediaQuery('(min-width: 1024px)')

  return (
    <>
      {header?.headermenu?.map((l, index: number) => {
        // let totalCount: number | undefined = undefined

        // if (l?.link?.url === '/vacatures/') {
        //   totalCount = totalVacancies
        // }

        // if (l?.link?.url === '/opdrachten/') {
        //   totalCount = totalAssignments
        // }

        return (
          <motion.li
            onHoverStart={() => isDesktop && setOpen(index)}
            onHoverEnd={() => isDesktop && setOpen(-1)}
            className="relative max-lg:my-2"
            key={index}
          >
            <div className="flex flex-wrap items-center relative">
              <LosseLink
                className="text-3xl relative  lg:aria-current-page:text-sprout lg:text-base font-nord font-medium text-mine-shaft lg:text-white lg:hover:text-outrageous-orange lg:hover:bg-white lg:px-3 py-1 rounded-md transition-all duration-200"
                to={l?.link?.url || '/'}
              >
                <span>{l?.link?.title}</span>
                {/* {totalCount && (
                  <div className="absolute text-xl lg:text-sm -right-11 lg:-right-5 top-0 bg-outrageous-orange lg:bg-white h-fit text-white lg:text-outrageous-orange px-1">
                    {totalCount}
                  </div>
                )} */}
              </LosseLink>
              {l?.submenu && (
                <div onClick={open === index ? () => setOpen(-1) : () => setOpen(index)} className="ml-[15px] lg:hidden">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    style={
                      open === index
                        ? {
                            transform: 'rotate(180deg)'
                          }
                        : {
                            transform: 'rotate(0deg)'
                          }
                    }
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#222222"
                    strokeWidth={3}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="transition-all"
                  >
                    <polyline points="6 9 12 15 18 9" />
                  </svg>
                </div>
              )}
            </div>
            {l?.submenu && open === index && (
              <AnimatePresence>
                <HeaderSubMenu fields={l.submenu} />
              </AnimatePresence>
            )}
          </motion.li>
        )
      })}
    </>
  )
}

export default Menu
