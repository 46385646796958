import { LossePlaatjie } from '@ubo/losse-sjedel'
import { Swiper, SwiperSlide } from 'swiper/react'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Misc } from '~/graphql/types'

export default function AltSlider({ fields }: { fields: Page_Flexcontent_Flex_Misc }) {
  return (
    <section data-component="Slider" className="relative overflow-hidden">
      <div className="container py-10 flex flex-col lg:gap-16 gap-6">
        <Content>{fields.description}</Content>
        <div>
          <Swiper
            loop
            // navigation
            // modules={[Navigation]}
            breakpoints={{
              0: {
                slidesPerView: 3,
                spaceBetween: 30
              },
              1200: {
                slidesPerView: 7,
                spaceBetween: 80
              }
            }}
          >
            {fields?.slider?.map((item, index) => (
              <SwiperSlide key={index}>
                <LossePlaatjie src={item} maxwidth={200} className="object-cover h-[88px]" />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  )
}
