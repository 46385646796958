import type { Page_Flexcontent_Flex_Cta } from '~/graphql/types'
import { LosseContent as Content } from '@ubo/losse-sjedel'
import { LosseLink as Link } from '@ubo/losse-sjedel'

export default function Centered({ fields }: { fields: Page_Flexcontent_Flex_Cta }) {
  return (
    <section id="Centered">
      <div className="container py-10 lg:py-16">
        <Content className="children-p:font-medium children-p:font-nord children-br:hidden lg:children-br:block children-p:text-lg md:children-p:text-2xl lg:children-p:text-4xl children-p:leading-snug">
          {fields.description}
        </Content>
        <div className="flex justify-center pt-6 lg:pt-12">
          <Link className="button-alt text-center" to={fields.link?.url}>
            {fields.link?.title}
          </Link>
        </div>
      </div>
    </section>
  )
}
