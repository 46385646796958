import type { Page_Flexcontent_Flex_Posts, Branche } from '~/graphql/types'
import { LossePlaatjie as Image, LosseLink as Link } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'

export default function Branches({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  return (
    <section id="Branches">
      <div className="container flex flex-col gap-6 lg:gap-12 py-10 md:py-16 lg:py-24">
        <div>
          <Content className="content">{fields.description}</Content>
        </div>
        <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-1 md:gap-3 xl:gap-6">
          {fields.posts?.edges?.map((post, index) => {
            const node = post?.node as Branche

            return (
              <div key={index} className="col-span-1 flex flex-col justify-end items-center aspect-square bg-sprout">
                <Image
                  className="w-[70px] h-[70px] lg:w-[50px] lg:h-[50px] xl:w-[70px] xl:h-[70px] 2xl:w-[120px] 2xl:h-[120px]"
                  src={node.recap?.image}
                  alt="branche-icon"
                />
                <div className="h-[60px] md:h-[70px] my-3 md:my-6 flex items-center flex-col justify-between">
                  <div className="font-nord font-medium text-base md:text-2xl leading-tight text-center">{node.title}</div>
                  <Link
                    className="text-outrageous-orange hover:underline text-sm md:text-xl font-nord font-medium text-center"
                    to={`/branche/${node.slug}`}
                  >
                    {fields.readmoretext}
                  </Link>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}
