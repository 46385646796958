import React from 'react'
import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'

// Components
import TeammembersOverview from './TeammembersOverview'

const TeammembersShell: React.FC<LosseFlexShellProps> = ({ fields, index }) => {
  const types: LosseFlexShellType = {
    overview: TeammembersOverview
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}

export default TeammembersShell
