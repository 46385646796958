import { FormDuufGroup, FormDuufSubmit } from '@ubo/form-duuf'
import { useState } from 'react'
import Loading from '~/components/elements/Loading'
import type { Page_Flexcontent_Flex_Misc } from '~/graphql/types'
import { LosseContent as Content } from '@ubo/losse-sjedel'
import Form from '~/components/elements/Form'

export default function Tool({ fields }: { fields: Page_Flexcontent_Flex_Misc }) {
  const [stepIndex, setStepIndex] = useState(0)
  const [results, setResults] = useState<
    Array<{
      question: string
      value: string
      answer: string
    }>
  >([])
  const [formDone, setFormDone] = useState(false)
  const [result1, setResult1] = useState<any>(0)
  const [result2, setResult2] = useState<any>(0)

  const handleAnswerClick = (index: number, answer: any, question: string) => {
    const copyResults = [...results]
    copyResults[index] = {
      question,
      value: answer.value,
      answer: answer.answer
    }
    setResults(copyResults)
  }

  return (
    <section id="Tool">
      <div className="bg-outrageous-orange z-10 h-[400px] absolute w-full top-0 left-0" />
      <div className="container relative z-20 flex justify-center items-center lg:px-28 pt-10 lg:pt-24 pb-10 lg:pb-80">
        <div className="bg-white flex flex-col gap-6 sm:gap-10 relative shadow-feed py-5 sm:py-14 lg:py-24 px-5 lg:px-32 w-full">
          {formDone ? (
            <div>
              <Content className="content">
                {fields.toolsettings?.resultdescription
                  ?.replace('{value_1}', fields.toolsettings.value1 || '')
                  .replace('{value_2}', fields.toolsettings.value2 || '')
                  .replace('{result_1}', `${result1}%`)
                  .replace('{result_2}', `${result2}%`)
                  .replace(
                    '{graph}',
                    `<div className="flex justify-center pb-10"><div className="flex gap-12 px-12 justify-evenly items-end border-b-2 border-mine-shaft border-solid h-[200px] max-w-[350px]">
                  <div style="height: ${result1}%" className="bg-outrageous-orange relative w-[80px]">
                    <div className="absolute w-full bottom-full font-prompt leading-5 text-outrageous-orange font-bold text-3xl text-center">
                      ${result1}%
                    </div>
                  </div>
                  <div style="height: ${result2}%" className="bg-sprout relative w-[80px]">
                    <div className="absolute w-full bottom-full font-prompt leading-5 text-sprout font-bold text-3xl text-center">${result2}%</div>
                  </div>
                </div></div>`
                  )}
              </Content>
            </div>
          ) : (
            <>
              <div>
                <Content className="content-xs">{fields.toolsettings?.title}</Content>
              </div>
              <div>
                <div className="relative h-[10px]">
                  <div className="w-full absolute top-0 left-0 z-10 h-full bg-cold-morning rounded-[10px]" />
                  <div
                    style={{ width: `${(100 / (fields.questions?.length || 1)) * (stepIndex + 1)}%` }}
                    className="absolute transition-all duration-200 top-0 left-0 z-20 h-full bg-sprout rounded-[10px]"
                  />
                </div>
                <div className="text-center font-nord text-lg sm:text-xl font-medium pt-3 sm:pt-6">
                  {stepIndex + 1 <= (fields.questions?.length || 1)
                    ? `Voortgang: stelling ${stepIndex + 1}/${fields.questions?.length}`
                    : `Voltooid!`}
                </div>
              </div>
              <div className="pt-5 lg:pt-10">
                {fields.questions?.map((question, index) => (
                  <div className={`${index === stepIndex ? `block` : `hidden`}`} key={index}>
                    <div className="font-nord font-medium text-xl md:text-2xl text-center">{question?.question}</div>
                    <div className="flex pt-6 justify-center">
                      {question?.answers?.map((answer, answerIndex) => (
                        <div
                          onClick={() => handleAnswerClick(index, answer, question?.question || '')}
                          className={`relative cursor-pointer group ${answerIndex === 0 ? `-right-2` : `-left-2`}`}
                          key={`${index}-${answerIndex}`}
                        >
                          {answerIndex === 0 ? (
                            <svg
                              className={`${
                                results![index]?.answer === answer?.answer ? `children-path:fill-sprout` : ``
                              } group-hover:children-path:fill-sprout w-[170px] lg:w-[268px]`}
                              width="268"
                              height="88"
                              viewBox="0 0 268 88"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                className="transition-all duration-200"
                                d="M2.5 2.5H263.893L220.978 85.5H2.5V2.5Z"
                                fill="#FFFFFF"
                                stroke="#D0DEBB"
                                strokeWidth="5"
                              />
                            </svg>
                          ) : (
                            <svg
                              className={`${
                                results![index]?.answer === answer?.answer ? `children-path:fill-outrageous-orange` : ``
                              } group-hover:children-path:fill-outrageous-orange w-[170px] lg:w-[261px]`}
                              width="261"
                              height="88"
                              viewBox="0 0 261 88"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                className="transition-all duration-200"
                                d="M4.04509 85.5L45.5451 2.5H258.5V85.5H4.04509Z"
                                fill="#FFFFFF"
                                stroke="#FF5C39"
                                strokeWidth="5"
                              />
                            </svg>
                          )}
                          <div
                            className={`absolute w-fit h-fit font-nord font-medium transition-all duration-200 group-hover:text-white text-xl top-0 bottom-0 ${
                              answerIndex === 0 ? `left-0 right-6 text-sprout` : `left-6 right-0 text-outrageous-orange`
                            } ${results![index]?.answer === answer?.answer ? `!text-white` : ``} m-auto`}
                          >
                            {answer?.answer}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
                <div className={`${stepIndex + 1 > (fields.questions?.length || 10) ? `block` : `hidden`}`}>
                  <div className="font-prompt font-medium text-2xl md:text-3xl text-center pb-5 md:pb-10">
                    Bedankt voor het invullen!
                    <br /> Nu de laatste 3 vragen:
                  </div>
                  <Form
                    onSubmit={(values: any) => {
                      setResult1(
                        ((100 / (fields.questions?.length || 0)) * results.filter((r) => r.value === 'waarde 1').length).toFixed(0)
                      )
                      setResult2(
                        ((100 / (fields.questions?.length || 0)) * results.filter((r) => r.value === 'waarde 2').length).toFixed(0)
                      )

                      let answerString = ''
                      let resultString = `${(
                        (100 / (fields.questions?.length || 0)) *
                        results.filter((r) => r.value === 'waarde 1').length
                      ).toFixed(0)}% - ${fields.toolsettings?.value1} \n ${(
                        (100 / (fields.questions?.length || 0)) *
                        results.filter((r) => r.value === 'waarde 2').length
                      ).toFixed(0)}% - ${fields.toolsettings?.value2}`

                      results.forEach((r) => {
                        answerString += `${r.question} : ${r.answer} \n`
                      })

                      values['input_15'] = answerString
                      values['input_16'] = resultString
                    }}
                    onSubmitDone={() => {
                      setFormDone(true)
                    }}
                    data={fields.toolsettings?.form}
                    renderLoading={() => <Loading />}
                    renderStatus={({ status, content }) => (
                      <div>
                        <h2>{status}</h2> <p>{content}</p>
                      </div>
                    )}
                    generate={false}
                  >
                    <div>
                      <div className="flex flex-col gap-3 lg:gap-10 md:flex-row items-end">
                        <div className="w-full md:w-1/3">
                          <FormDuufGroup showIds={[3]} />
                        </div>
                        <div className="w-full md:w-1/3">
                          <FormDuufGroup showIds={[17]} />
                        </div>
                        <div className="w-full md:w-1/3">
                          <FormDuufGroup showIds={[5]} />
                        </div>
                      </div>
                      <div className="flex pt-3 lg:pt-10 justify-center">
                        <FormDuufSubmit />
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
              <div className="flex pt-5 lg:pt-20 justify-between">
                <div>
                  {stepIndex !== 0 && (
                    <div
                      className="hover:underline font-nord font-medium text-xl text-cold-morning flex items-center gap-4 cursor-pointer"
                      onClick={() => setStepIndex(stepIndex - 1)}
                    >
                      <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="26" cy="26.0001" r="26" transform="rotate(180 26 26.0001)" fill="#E5E5E5" />
                        <path
                          d="M23.8919 26.212L34 44.0002L27.1081 44.0002L17 26.212L27.1081 8.00024L34 8.00024L23.8919 26.212Z"
                          fill="white"
                        />
                      </svg>
                      <div className="md:block hidden">{fields.toolsettings?.back}</div>
                    </div>
                  )}
                </div>
                <div>
                  {stepIndex + 1 <= (fields.questions?.length || 1) && (
                    <div
                      className={`${
                        results[stepIndex] ? `` : `pointer-events-none opacity-50`
                      } hover:underline font-nord font-medium text-xl text-outrageous-orange flex items-center gap-4 cursor-pointer`}
                      onClick={() => setStepIndex(stepIndex + 1)}
                    >
                      <div className="md:block hidden">{fields.toolsettings?.next}</div>
                      <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="26" cy="26" r="26" fill="#FF5C39" />
                        <path d="M28.1081 25.7882L18 8H24.8919L35 25.7882L24.8919 44H18L28.1081 25.7882Z" fill="white" />
                      </svg>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  )
}
