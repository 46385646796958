import type { Page_Flexcontent_Flex_Cta } from '~/graphql/types'
import { LosseContent as Content, LosseLink as Link, LossePlaatjie as Image } from '@ubo/losse-sjedel'

export default function TwoBlocks({ fields }: { fields: Page_Flexcontent_Flex_Cta }) {
  return (
    <section id="TwoBlocks" className="py-10 md:py-16 lg:py-24">
      <div className="container">
        <Content className="content">{fields.description}</Content>
        <div className="grid grid-cols-[repeat(17,_minmax(0,_1fr))] lg:px-20 py-5 sm:py-10 lg:py-14">
          {fields.blocks?.map((block, index) => (
            <Link
              to={block?.link?.uri}
              key={index}
              className={`${index === 1 ? `col-span-10` : `col-span-7`} group relative flex items-center`}
            >
              <Content className="absolute z-20 my-auto text-shadow children-h3:text-2xl sm:children-h3:text-4xl lg:children-h3:text-6xl children-h3:font-nord children-h3:font-medium group-hover:children-h3:text-outrageous-orange children-h3:text-white children-h3:transition-all children-h3:duration-200 left-0 right-0 mx-auto">
                {block?.description}
              </Content>
              <div className="relative z-10 w-full">
                {index !== 1 && (
                  <div
                    className={`absolute h-full ${
                      index === 0 ? `right-0 bg-gradient-to-l` : `left-0 bg-gradient-to-r`
                    } top-0 w-[90px] from-mine-shaft to-transparent`}
                  />
                )}
                <Image maxwidth={800} className="w-full" src={block?.image} alt="block-image" />
              </div>
            </Link>
          ))}
        </div>
        <div className="flex justify-center">
          <Link className="button-alt" to={fields.link?.url}>
            {fields.link?.title}
          </Link>
        </div>
      </div>
    </section>
  )
}
