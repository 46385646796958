/* eslint-disable react/no-array-index-key */
import React from 'react'
import { motion } from 'framer-motion'
import type { Maybe, Component_Header_Headermenu_Submenu } from '~/graphql/types'
import { LosseLink } from '@ubo/losse-sjedel'

interface HeaderSubMenuProps {
  fields: Maybe<Component_Header_Headermenu_Submenu>[]
}

const HeaderSubMenu: React.FC<HeaderSubMenuProps> = function ({ fields }) {
  return (
    <motion.ul
      className="relative w-fit overflow-hidden lg:absolute"
      initial={{ height: 0 }}
      animate={{ height: 'fit-content' }}
      exit={{ height: 0 }}
    >
      <div className="lg:pt-3">
        <div className="py-1 pr-2 pl-3 lg:rounded-md bg-white lg:px-2 lg:py-3 lg:shadow-2xl">
          {fields.map((f, index: number) => (
            <li className="list-none" key={index}>
              <LosseLink
                className="text-outrageous-orange block px-3 py-1 lg:rounded-lg lg:hover:bg-sprout whitespace-nowrap font-nord font-medium lg:text-primary"
                to={f?.link?.url || '/'}
              >
                {f?.link?.title}
              </LosseLink>
            </li>
          ))}
        </div>
      </div>
    </motion.ul>
  )
}

export default HeaderSubMenu
