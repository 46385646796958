import React from 'react'
import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'

// Components
import Centered from './Centered'
import ThreeBlocks from './ThreeBlocks'
import TwoBlocks from './TwoBlocks'
import Apply from './Apply'

const CtaShell: React.FC<LosseFlexShellProps> = ({ fields, index }) => {
  const types: LosseFlexShellType = {
    centered: Centered,
    threeblocks: ThreeBlocks,
    twoblocks: TwoBlocks,
    apply: Apply
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}

export default CtaShell
