import { LosseLink, type LoaderData, useLoaderData } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'
import { LossePlaatjie as Image } from '@ubo/losse-sjedel'
import type { Component_Footer } from '~/graphql/types'

export interface FooterLoaderData extends Omit<LoaderData, 'footer'> {
  footer: Component_Footer
}

export default function Footer() {
  const { footer } = useLoaderData<FooterLoaderData>()

  return (
    <footer>
      <div className="bg-outrageous-orange">
        <div className="container text-white">
          <div className="grid lg:gap-0 gap-4 grid-cols-3 lg:grid-cols-5 py-10 lg:pt-10 lg:pb-8">
            <div className="col-span-3 lg:col-span-2 flex items-center">
              <LosseLink aria-label="Home" to="/">
                <svg
                  className={`w-[276] transition-all duration-200`}
                  width="276"
                  height="52"
                  viewBox="0 0 276 52"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_204_22)">
                    <path d="M26.4779 1.57648H38.2463L13.0103 52H0.583984L26.4779 1.57648Z" fill="white" />
                    <path
                      d="M79.9532 22.3245H49.9629C51.1986 28.1683 56.3362 31.6504 64.5976 31.6504C70.2558 31.6504 75.0044 29.7448 78.6487 27.1232V35.7908C74.7469 38.6781 68.5681 40.7165 62.0631 40.7165C48.0119 40.7165 38.9668 32.2452 38.9668 20.032C38.9668 8.86396 46.9707 0 60.4383 0C73.1221 0 79.9532 6.95836 79.9532 18.2534V22.3245ZM50.0944 16.4171H70.5876C70.067 11.4914 66.6171 8.66763 60.7015 8.66763C54.7858 8.66763 51.0728 11.8205 50.1002 16.4171"
                      fill="white"
                    />
                    <path
                      d="M85.4854 35.5194V26.921C90.171 30.5994 96.6759 32.5686 102.786 32.5686C107.535 32.5686 109.812 31.0614 109.812 28.6303C109.812 27.1174 109.228 25.5467 105.389 24.5592L97.1279 22.5901C88.735 20.4882 85.6799 16.8791 85.6799 11.6242C85.6799 4.72937 92.3793 0 103.375 0C110.338 0 115.607 1.90561 119.183 4.20389V12.4788C115.539 9.98423 110.401 8.0151 104.417 8.0151C99.2791 8.0151 96.8705 9.45874 96.8705 11.6242C96.8705 13.5298 98.5639 14.5115 102.466 15.3661L109.88 17.0061C116.969 18.4498 121.265 21.6027 121.265 28.3012C121.265 35.6522 114.823 40.7107 103.639 40.7107C97.0707 40.7107 90.6287 38.9379 85.4911 35.5252"
                      fill="white"
                    />
                    <path
                      d="M189.314 1.57648L176.887 39.5269H165.245L156.137 12.4096L147.16 39.5269H135.844L123.549 1.57648H135.123L142.154 27.5794L150.673 1.57648H162.184L170.708 27.7064L178.123 1.57648H189.314Z"
                      fill="white"
                    />
                    <path
                      d="M229.196 18.9117V39.5269H218.394V34.4049C215.791 38.6723 211.306 40.8378 205.384 40.8378C196.991 40.8378 191.659 35.3866 191.659 28.2954C191.659 21.2042 197.192 15.9494 205.974 15.9494C211.5 15.9494 215.665 17.7857 218.331 21.4006V19.8934C218.268 12.6694 213.062 9.12382 206.036 9.12382C201.03 9.12382 196.928 10.8966 193.61 14.1766V4.92571C197.192 2.29828 202.135 0 209.229 0C221.781 0 229.196 6.6985 229.196 18.9117ZM218.394 27.7122C218.394 24.3629 215.013 22.0011 209.938 22.0011C204.864 22.0011 202.066 24.4322 202.066 27.7815C202.066 31.1307 204.927 33.6888 209.807 33.6888C214.687 33.6888 218.394 31.1249 218.394 27.7122Z"
                      fill="white"
                    />
                    <path d="M264.226 1.57648H276L250.758 52H238.332L264.226 1.57648Z" fill="white" />
                    <path d="M10.956 1.57648H0V31.6504H10.956V1.57648Z" fill="white" />
                    <path d="M248.642 1.57648H237.686V31.6504H248.642V1.57648Z" fill="white" />
                  </g>
                  <defs>
                    <clipPath id="clip0_204_22">
                      <rect width="276" height="52" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </LosseLink>
            </div>
            <div className="col-span-3 sm:col-span-1">
              <h2 className="pb-1 lg:pb-2 text-lg font-nord font-medium">{footer.linkslabel}</h2>
              <ul>
                {footer?.links?.map((item: any, index: number) => (
                  <li className="py-[2px]" key={index}>
                    <LosseLink className="font-prompt font-light text-base hover:underline" to={item?.link?.url || '/'}>
                      {item?.link?.title}
                    </LosseLink>
                  </li>
                ))}
              </ul>
            </div>
            <div className="col-span-3 sm:col-span-1">
              <h2 className="pb-1 lg:pb-2 text-lg font-nord font-medium">{footer.contentlabel}</h2>
              <Content className="children-p:font-prompt children-p:font-light children-p:text-base">{footer.address}</Content>
            </div>
            <div className="col-span-3 sm:col-span-1">
              <h2 className="pb-1 lg:pb-2 text-lg font-nord font-medium">{footer.socialslabel}</h2>
              <div className="flex gap-5">
                {footer.socials?.map((item, index) => (
                  <LosseLink target="_blank" key={index} to={item?.link?.url}>
                    <Image className="hover:children-path:fill-sprout" src={item?.icon} alt="social-icon" />
                  </LosseLink>
                ))}
              </div>
            </div>
            <div className="col-span-2" />
            <div className="col-span-3">
              <div className="flex justify-start pt-8 pb-8 lg:pb-16">
                <div className="flex flex-col gap-6 justify-center">
                  <div className="font-medium font-nord text-lg">{footer.downloadtext}</div>
                  <div className="flex items-center gap-5">
                    <a href={footer.apple?.url || '/'} target={footer?.apple?.target || '_blank'}>
                      <img src="/images/apple.png" alt="apple-app-store-button" />
                    </a>
                    <a href={footer.android?.url || '/'} target={footer?.android?.target || '_blank'}>
                      <img src="/images/android.png" alt="android-app-store-button" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-sprout flex flex-wrap justify-center items-center">
        {footer.extralinks?.map((item: any, index: number) => (
          <div className="py-3 flex group" key={index}>
            <LosseLink className="font-prompt hover:underline text-base font-light" to={item.link.url}>
              {item.link.title}
            </LosseLink>
            <div className="group-last:hidden px-4 font-prompt font-black text-base text-outrageous-orange">|</div>
          </div>
        ))}
      </div>
    </footer>
  )
}
