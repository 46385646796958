import type { Post } from '~/graphql/types'
import { LosseContent as Content } from '@ubo/losse-sjedel'
import { LosseLink as Link } from '@ubo/losse-sjedel'
import { LossePlaatjie as Image } from '@ubo/losse-sjedel'

export default function FeedPreview({ fields, size = 'normal' }: { fields: Post; size?: 'normal' | 'big' | 'bigger' }) {
  return (
    <Link id="FeedPreview" to={fields.uri} className={`${fields.recap?.backgroundcolor} block group relative aspect-square`}>
      {fields.recap?.imageortext === 'image' && <Image maxwidth={800} src={fields.recap.image} className="object-cover" alt="feed-image" />}
      <div className="absolute flex flex-col justify-between h-full w-full top-0 left-0">
        <div className="flex justify-end">
          <div className="bg-sprout px-3 sm:px-5 uppercase py-1 sm:py-2 font-nord font-medium text-base sm:text-xl">
            {fields.categories?.edges![0]?.node?.name}
          </div>
        </div>
        {fields.recap?.imageortext === 'image' ? (
          <div className="pr-24 relative">
            <div className="absolute h-[250px] w-full z-10 bg-gradient-to-t from-mine-shaft to-transparent left-0 bottom-0" />
            <div
              className={`font-prompt relative pl-3 lg:pl-10 pb-3 lg:pb-6 z-20 text-white ${
                { normal: `text-base`, big: `text-base lg:text-xl !leading-snug`, bigger: `text-3xl leading-relaxed` }[size]
              } leading-[20px] sm:leading-[32px] font-medium`}
            >
              {fields.title}
            </div>
          </div>
        ) : (
          <div className="absolute px-6 2xl:px-16 h-full w-full flex justify-center items-center">
            <Content
              className={`${
                fields.recap?.backgroundcolor === 'bg-sprout' ? `children-p:text-outrageous-orange` : `children-p:text-sprout`
              } ${
                {
                  normal: `children-p:text-base sm:children-p:text-xl`,
                  big: `children-p:text-xl s:children-p:text-base md:children-p:text-lg lg:children-p:text-3xl`,
                  bigger: `children-p:text-5xl`
                }[size]
              } children-p:leading-normal sm:children-p:leading-relaxed children-p:font-nord children-p:font-medium`}
            >
              {fields.recap?.description || ''}
            </Content>
          </div>
        )}
        <svg
          className="absolute z-20 w-[40px] h-[40px] sm:w-[50px] sm:h-[50px] bottom-3 right-3 sm:bottom-5 sm:right-5"
          width="52"
          height="52"
          viewBox="0 0 52 52"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle className="fill-outrageous-orange transition-all duration-200 group-hover:fill-sprout" cx="26" cy="26" r="26" />
          <path d="M28.1081 25.7882L18 8H24.8919L35 25.7882L24.8919 44H18L28.1081 25.7882Z" fill="white" />
        </svg>
      </div>
    </Link>
  )
}
