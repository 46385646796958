import type { Page_Flexcontent_Flex_Misc } from '~/graphql/types'
import { LossePlaatjie as Image } from '@ubo/losse-sjedel'

export default function Images({ fields }: { fields: Page_Flexcontent_Flex_Misc }) {
  return (
    <section id="Images">
      <div className="container section">
        <div className="flex justify-center flex-wrap lg:grid lg:grid-cols-[repeat(17,_minmax(0,_1fr))]">
          {fields.images?.map((item, index) => (
            <div
              key={index}
              className={`${
                index === 1
                  ? `${fields.swapped ? `col-span-10` : `w-[70%] lg:w-[unset] col-span-7`}`
                  : `${!fields.swapped ? `col-span-10` : `w-[70%] lg:w-[unset] col-span-7`}`
              } group relative flex items-center`}
            >
              <div className="relative z-10 w-full">
                {index > 0 && (
                  <div
                    className={`absolute h-full ${
                      index === 0 ? `lg:right-0 lg:bg-gradient-to-l` : `lg:left-0 lg:bg-gradient-to-r`
                    } top-0 lg:w-[90px] max-lg:w-full max-lg:h-[90px] max-lg:bg-gradient-to-b from-mine-shaft to-transparent`}
                  />
                )}
                <Image maxwidth={1000} autoheight={false} className="w-full" src={item?.image} alt="block-image" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
