import type { Page_Flexcontent_Flex_Posts, Post } from '~/graphql/types'
import { LosseContent as Content, useLoaderData, LosseLink as Link } from '@ubo/losse-sjedel'
import Slider from 'react-slick'
import FeedPreview from '~/components/elements/FeedPreview'
import NotSoEndlessContainer from '~/components/elements/NotSoEndlessContainer'
import type { LoaderData } from '@ubo/losse-sjedel'

export default function FeedSlider({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  const { page } = useLoaderData<LoaderData<Post>>()

  return (
    <section id="FeedSlider" className="overflow-hidden">
      <div className="container py-10 md:py-16 lg:py-24">
        <Content className="content">{fields.description}</Content>
        <div className="py-5 sm:py-8 md:py-12">
          <NotSoEndlessContainer>
            <div className="absolute pointer-events-none bg-gradient-to-l z-20 w-[100px] sm:w-[200px] h-full top-0 right-0 from-white to-transparent" />
            <Slider
              draggable
              responsive={[
                {
                  breakpoint: 1399,
                  settings: {
                    slidesToShow: 2.8
                  }
                },
                {
                  breakpoint: 1023,
                  settings: {
                    slidesToShow: 1.8
                  }
                },
                {
                  breakpoint: 639,
                  settings: {
                    slidesToShow: 1.2
                  }
                }
              ]}
              arrows={false}
              infinite={false}
              speed={500}
              slidesToShow={3.8}
              slidesToScroll={1}
            >
              {page.contentType?.node?.name === 'post'
                ? fields.posts?.edges
                    ?.filter((f) => f?.node?.databaseId !== page.databaseId)
                    .map((post, index) => {
                      const node = post?.node as Post

                      return (
                        <div className="px-2 lg:px-5" key={index}>
                          <FeedPreview fields={node} />
                        </div>
                      )
                    })
                : fields.posts?.edges?.map((post, index) => {
                    const node = post?.node as Post

                    return (
                      <div className="px-2 lg:px-5" key={index}>
                        <FeedPreview fields={node} />
                      </div>
                    )
                  })}
            </Slider>
          </NotSoEndlessContainer>
        </div>

        <div className="flex justify-center">
          <Link className="button-alt" to="/feed/">
            {fields.loadmoretext}
          </Link>
        </div>
      </div>
    </section>
  )
}
