import { useLoaderData, LosseContent as Content } from '@ubo/losse-sjedel'
import type { Page_Flexcontent_Flex_Text, Vacancy } from '~/graphql/types'
import type { LoaderData } from '@ubo/losse-sjedel'

export default function Detail({ fields }: { fields: Page_Flexcontent_Flex_Text }) {
  const { page } = useLoaderData<LoaderData<Vacancy>>()
  const data = JSON.parse(page.data?.data || '')

  return (
    <section id="Detail">
      <div className="container py-5 md:py-10 xl:py-20">
        <div className="grid grid-cols-3">
          <div className="col-span-3 lg:col-span-2 flex flex-col gap-16 lg:pr-10">
            <div className="flex flex-col gap-8 children-h2:text-xl md:children-h2:text-2xl lg:children-h2:text-3xl children-h2:font-nord children-h2:font-medium children-h2:text-outrageous-orange children-h2:pb-3">
              {/* FUNCTIE */}
              <div>
                {data.Descriptions.FunctionDescription && <Content className="content">{data.Descriptions.FunctionDescription}</Content>}
              </div>

              {/* OVER DE OPDRACHTGEVER */}
              <div>
                <h2>Over de opdrachtgever</h2>
                {data.Descriptions.ClientDescription && (
                  <Content className="content">{data.Descriptions.ClientDescription.replace(/&lt;/g, '<').replace(/&gt;/g, '>')}</Content>
                )}
              </div>
              {page.contentType?.node?.name === 'internalVacancy' && (
                <div>
                  {/* EISEN */}
                  <h2>Eisen</h2>
                  {data.Descriptions.RequirementsDescription && (
                    <Content className="content">{data.Descriptions.RequirementsDescription}</Content>
                  )}
                </div>
              )}
              <div>
                {/* WAT WIJ BIEDEN */}
                <h2>Wat wij bieden</h2>
                {data.Descriptions.OfferDescription && <Content className="content">{data.Descriptions.OfferDescription}</Content>}
              </div>
              <div>
                {/* MEER INFORMATIE */}
                <h2>Meer informatie</h2>
                {data.Descriptions.AdditionalDescription && (
                  <Content className="content">{data.Descriptions.AdditionalDescription}</Content>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
