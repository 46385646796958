import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Form } from '~/graphql/types'
import Loading from '~/components/elements/Loading'
import Form from '~/components/elements/Form'

export default function FormEvent({ fields }: { fields: Page_Flexcontent_Flex_Form }) {
  return (
    <section id="FormEvent">
      <div className="container flex flex-col items-center">
        <Content className="py-10 lg:w-2/3">{fields.intro}</Content>
        <div className="bg-white shadow-feed px-6 s:px-16 xl:px-32 py-6 s:py-12 xl:py-16 w-full lg:w-2/3">
          <Content>{fields?.description}</Content>
          <div className="mt-5">
            <Form
              data={fields.form}
              privacyUrl="/privacy"
              privacyUrlPlacement="privacy"
              renderLoading={() => <Loading />}
              renderStatus={({ status, content }) => <Content>{content}</Content>}
            />
          </div>
        </div>
      </div>
    </section>
  )
}
