import { type LoaderData, useLoaderData, pageLoader as loader, useLocation, useTransition } from '@ubo/losse-sjedel'
import { useEffect, useState } from 'react'

import FlexContentHandler from '~/components/flex/FlexContentHandler'
import Layout from '~/components/layout/Layout'

import Progress from 'nprogress'
import OpenApplyForm from '~/components/elements/OpenApplyForm.client'

Progress.configure({ showSpinner: false })

export { loader }

export default function $page() {
  const {
    page: { contentType, flexContent }
  } = useLoaderData<LoaderData>()
  const location = useLocation()
  const transition = useTransition()
  // const layout = losseUseLayout()
  const [openApply, setOpenApply] = useState(false)

  useEffect(() => {
    if (transition.state === 'loading' || transition.state === 'submitting') {
      Progress.start()
    } else {
      Progress.done()
    }
  }, [transition.state])

  useEffect(() => {
    if (location.hash === '#open-sollicitatie') {
      setOpenApply(true)
      // layout.setScrollable(false)
    } else {
      setOpenApply(false)
      // layout.setScrollable(true)
    }
  }, [location])

  return (
    <Layout>
      {openApply && <OpenApplyForm />}
      <FlexContentHandler prefix={`${contentType?.node?.name}_Flexcontent`} flex={flexContent?.flex} />
    </Layout>
  )
}
