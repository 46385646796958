import { LossePlaatjie } from '@ubo/losse-sjedel'
import { useState } from 'react'
import Content from '~/components/elements/Content'
import Form from '~/components/elements/Form'
import Loading from '~/components/elements/Loading'
import type { Page_Flexcontent_Flex_Form } from '~/graphql/types'

export default function Brochure({ fields }: { fields: Page_Flexcontent_Flex_Form }) {
  const [show, setShow] = useState(false)

  return (
    <section data-component="Brochure" className="relative overflow-hidden">
      <div className="container py-10 md:py-16 lg:py-24">
        <div className="flex justify-center flex-wrap lg:grid lg:grid-cols-[repeat(17,_minmax(0,_1fr))]">
          <div className={`col-span-10 group relative flex items-center`}>
            <div className="relative flex flex-col gap-6 lg:gap-14 bg-outrageous-orange p-8 md:p-16 lg:px-32 lg:py-24 z-10 w-full">
              {show ? (
                <>
                  <Content className="content">{fields.intro}</Content>
                  <Form
                    className="form-alternative-button"
                    data={fields.form}
                    privacyUrl="/privacy"
                    privacyUrlPlacement="privacy"
                    renderLoading={() => <Loading />}
                    renderStatus={({ status, content }) => <Content>{content}</Content>}
                  />
                </>
              ) : (
                <>
                  <Content className="content children-headings:text-white">{fields.description}</Content>
                  <div className="flex">
                    <div onClick={() => setShow(true)} className="button cursor-pointer">
                      Download brochure
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className={`w-[70%] lg:w-[unset] col-span-7 group relative flex items-center`}>
            <div className="relative lg:h-[70%] z-10 w-full">
              <LossePlaatjie className="h-full object-cover" src={fields.image} alt="brochure-image" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
