import { useSearchParams } from '@ubo/losse-sjedel'
import Slider from 'rc-slider'

export default function RangeFilter() {
  const [searchParams, setSearchParams] = useSearchParams()

  function handleRange(e: any) {
    searchParams.set('minimum-uur', e[0])
    searchParams.set('maximum-uur', e[1])

    setSearchParams(searchParams)
  }

  return (
    <section id="RangeFilter" className="w-full pb-4 xs:py-4">
      <label className="block capitalize font-prompt text-base md:text-xl font-light pb-3" htmlFor="postcode">
        Aantal uur per week
      </label>
      <Slider
        marks={{
          0: '0',
          8: '8',
          16: '16',
          24: '24',
          32: '32',
          40: '40'
        }}
        range
        allowCross={false}
        min={0}
        max={40}
        defaultValue={[parseInt(searchParams.get('minimum-uur') || '0'), parseInt(searchParams.get('maximum-uur') || '40')]}
        onAfterChange={handleRange}
      />
    </section>
  )
}
