import { useLoaderData, LosseContent as Content, LosseLink as Link, LossePlaatjie } from '@ubo/losse-sjedel'
import type { Assignment, InternalVacancy, Page_Flexcontent_Flex_Banner, Vacancy } from '~/graphql/types'
import type { LoaderData } from '@ubo/losse-sjedel'
import { useEffect } from 'react'
import type { Feed } from '~/types/declarations'

export default function BannerDetail({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  const { page } = useLoaderData<LoaderData<Vacancy | Assignment | InternalVacancy>>()
  const data = JSON.parse(page.data?.data || '') as Feed.FeedObject

  function scrollEvent() {
    const element = document.getElementById('white-block')

    if (element) {
      element.style.top = `${window.scrollY}px`
      element.style.scrollBehavior = 'smooth'
    }
  }

  useEffect(() => {
    document.addEventListener('scroll', scrollEvent)
  }, [])

  return (
    <>
      <section id="BannerDetail" className="bg-outrageous-orange">
        <div className="container">
          <div className="grid grid-cols-3 pb-8 lg:pb-10 pt-8 lg:pt-14">
            <div className="col-span-3 lg:col-span-2">
              <div className="flex flex-col lg:min-h-[180px] justify-between">
                <Content className="content children-headings:text-white">{fields.description}</Content>
                <div className="pt-4 lg:pt-0 flex lg:flex-row flex-col gap-2 lg:gap-14">
                  {data?.WorkLocation?.City && (
                    <div className="col-span-1 flex items-center gap-2 font-prompt font-light text-lg lg:text-xl text-white">
                      <svg className="w-[25px]" width="27" height="32" viewBox="0 0 27 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M23.0459 3.92928C20.4959 1.39523 17.1058 0 13.5 0C9.89423 0 6.50408 1.39523 3.95406 3.92928C1.40403 6.46332 0 9.83222 0 13.4154C0 16.3973 1.91827 20.1225 5.70152 24.4869C8.72763 27.9786 11.9155 30.6589 12.2644 30.9491L13.4982 32L14.7387 30.9569C15.3244 30.4651 18.3947 27.8408 21.2997 24.4875C25.0823 20.1219 27 16.3967 27 13.4154C27 9.83222 25.596 6.46332 23.0459 3.92928ZM23.1622 13.4154C23.1622 15.1477 21.8957 17.6468 19.5001 20.6425C17.2905 23.4059 14.7659 25.8081 13.4994 26.9584C12.2323 25.8075 9.70767 23.4065 7.49865 20.6449C5.10308 17.6498 3.83655 15.1501 3.83655 13.416C3.83958 10.8525 4.84566 8.4418 6.67005 6.62945C8.49444 4.81649 10.9191 3.81672 13.4988 3.81371C16.0785 3.81672 18.5037 4.81649 20.3275 6.62945C22.1519 8.4424 23.158 10.8525 23.161 13.4154H23.1622Z"
                          fill="#D0DEBB"
                        />
                        <path
                          d="M13.5 7.78873C10.3782 7.78873 7.83783 10.3125 7.83783 13.4154C7.83783 16.5182 10.3776 19.0421 13.5 19.0421C16.6224 19.0421 19.1621 16.5182 19.1621 13.4154C19.1621 10.3125 16.6224 7.78873 13.5 7.78873ZM15.5588 13.4154C15.5588 14.5434 14.6351 15.4613 13.5 15.4613C12.3649 15.4613 11.4412 14.5434 11.4412 13.4154C11.4412 12.2874 12.3649 11.3695 13.5 11.3695C14.6351 11.3695 15.5588 12.2874 15.5588 13.4154Z"
                          fill="#D0DEBB"
                        />
                      </svg>
                      {data.WorkLocation.City}
                    </div>
                  )}
                  {/* UREN */}
                  {data?.Employment && (
                    <div className="col-span-1 flex items-center gap-2 font-prompt font-light text-lg lg:text-xl text-white">
                      <svg className="w-[25px]" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M16 0C7.16375 0 0 7.16375 0 16C0 24.8363 7.16375 32 16 32C24.8325 31.9906 31.9906 24.8325 32 16C32 7.16375 24.8363 0 16 0ZM16 28C9.3725 28 4 22.6275 4 16C4 9.3725 9.3725 4 16 4C22.6244 4.0075 27.9925 9.37563 28 16C28 22.6275 22.6275 28 16 28Z"
                          fill="#D0DEBB"
                        />
                        <path d="M13.9998 14.74L9.10547 17.8057L11.228 21.1944L17.9998 16.9532V8.36255H13.9998V14.74Z" fill="#D0DEBB" />
                      </svg>
                      {`${data.Employment.HoursPerWeekMin} - ${data.Employment.HoursPerWeekMax} uur`}
                    </div>
                  )}

                  {/* EDUCATIE */}
                  {data?.Facets?.EducationLevels![0] && page.contentType?.node?.name !== 'internalVacancy' && (
                    <div className="col-span-1 flex items-center gap-2 font-prompt font-light text-lg lg:text-xl text-white">
                      <svg className="w-[25px]" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4 28V0H0V28C0 30.2094 1.79063 32 4 32H32V28H4Z" fill="#D0DEBB" />
                        <path d="M28 12H24V24H28V12Z" fill="#D0DEBB" />
                        <path d="M12 12H8V24H12V12Z" fill="#D0DEBB" />
                        <path d="M20 5.33301H16V23.9999H20V5.33301Z" fill="#D0DEBB" />
                      </svg>
                      {data.Facets.EducationLevels[0].Name}
                    </div>
                  )}
                  {/* SALARIS */}
                  {data?.Salary &&
                    data?.Salary?.SalaryMax !== 0 &&
                    data?.Salary?.SalaryMin !== 0 &&
                    page.contentType?.node?.name !== 'assignment' && (
                      <div className="col-span-1 flex items-center gap-2 font-prompt font-light text-lg lg:text-xl text-white">
                        <svg className="w-[25px]" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M14.8234 11.1937C16.114 10.7581 17.4971 10.8506 18.7177 11.4556C19.3671 11.7768 19.9296 12.2237 20.3909 12.7831L20.5896 13.0243L22.4652 11.4762L22.2665 11.2349C20.9871 9.68244 19.1796 8.72182 17.1771 8.52869C15.1752 8.33557 13.2171 8.93432 11.6646 10.2137C10.6652 11.0374 9.89586 12.1049 9.42961 13.3106H7.70898V15.7418H8.92086C8.91586 15.8362 8.91273 15.9324 8.91273 16.0312C8.91273 16.1299 8.91586 16.2268 8.92086 16.3206H7.70898V18.7518H9.42961C10.5752 21.7206 13.4271 23.5699 16.4621 23.5693C17.2915 23.5693 18.1352 23.4312 18.9609 23.1399C20.2521 22.6843 21.3952 21.8849 22.2665 20.8281L22.4652 20.5868L20.5896 19.0387L20.3909 19.2806C19.4196 20.4612 17.9871 21.1374 16.4584 21.1374H16.4515C14.6746 21.1349 13.0646 20.2331 12.1365 18.7518H17.9677V16.3206H11.3534C11.3415 16.1274 11.3415 15.9343 11.3534 15.7412H17.9677V13.3099H12.1359C12.7584 12.3156 13.6965 11.5724 14.8227 11.1924L14.8234 11.1937Z"
                            fill="#D0DEBB"
                          />
                          <path
                            d="M16 0C7.16375 0 0 7.16375 0 16C0 24.8363 7.16375 32 16 32C24.8325 31.9906 31.9906 24.8325 32 16C32 7.16375 24.8363 0 16 0ZM16 28C9.3725 28 4 22.6275 4 16C4 9.3725 9.3725 4 16 4C22.6244 4.0075 27.9925 9.37563 28 16C28 22.6275 22.6275 28 16 28Z"
                            fill="#D0DEBB"
                          />
                        </svg>
                        {page.contentType?.node?.name === 'internalVacancy'
                          ? `${data.Salary.Description}`
                          : `${data?.Salary?.SalaryMin} - ${data?.Salary?.SalaryMax}`}
                      </div>
                    )}
                </div>
              </div>
            </div>
            <div className="col-span-3 lg:col-span-1 relative lg:block hidden">
              <div id="white-block" className="absolute bg-white w-full shadow-feed top-0 right-0">
                <div className="flex flex-col gap-5 p-10 2xl:p-20 items-center justify-center">
                  <Link
                    className="button-alt px-4 !w-[unset] flex gap-5 items-center"
                    to={page.contentType?.node?.name === 'internalVacancy' ? `#Apply` : `#video-solliciteren`}
                  >
                    {page.contentType?.node?.name === 'internalVacancy' ? (
                      <>Solliciteer nu</>
                    ) : (
                      <>
                        <svg width="33" height="24" viewBox="0 0 33 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M30.8151 3.6C29.6428 3.19187 28.3357 3.39687 27.3561 4.1425L25.864 5.22C25.1306 2.6325 22.958 0.665625 20.2459 0.133125L7.31192 0C3.27345 0 0 3.17437 0 7.09062V16.9094C0 20.8256 3.27345 24 7.31192 24H18.8118C22.1046 23.9956 24.9901 21.8612 25.864 18.7825L27.514 19.9706C28.1063 20.4013 28.8255 20.635 29.5654 20.6375C30.2892 20.6338 30.9943 20.4106 31.5808 20C32.5005 19.3213 33.0277 18.255 32.9981 17.1344V6.7025C33.0013 5.33 32.1338 4.0975 30.8145 3.6H30.8151ZM21.9989 16.9094C21.9963 18.6156 20.5713 19.9981 18.8118 20H7.31192C5.55242 19.9975 4.12677 18.6156 4.12484 16.9094V7.09062C4.12742 5.38437 5.55242 4.00187 7.31192 4H18.8118C20.5713 4.0025 21.997 5.38437 21.9989 7.09062V16.9094Z"
                            fill="white"
                          />
                        </svg>
                        Solliciteer via video
                      </>
                    )}
                  </Link>
                  <div className="font-nord text-xl">of</div>
                  <Link className="button-alt-outline px-4 !w-[unset]" to="/contact/">
                    Kom eens kennis maken
                  </Link>
                </div>
                <div className="grid grid-cols-11 gap-4 xl:gap-8 xl:px-8">
                  <div className="col-span-5 overflow-hidden relative">
                    <LossePlaatjie
                      maxwidth={850}
                      className="relative z-20"
                      src={fields.teammember?.teammembercontent?.image}
                      alt="person-image"
                    />
                    <svg
                      className="absolute z-10 top-0 right-0"
                      width="188"
                      height="250"
                      viewBox="0 0 188 250"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M129.255 0H188L62.0289 250H0L129.255 0Z" fill="#D0DEBB" />
                    </svg>

                    {/* <div className="bg-sprout w-[40px] h-[300px] rotate-[30deg] absolute -bottom-[30px] left-1/2" /> */}
                  </div>
                  <div className="col-span-6 flex justify-center flex-col">
                    <Content className="content">{fields.teammember?.teammembercontent?.description}</Content>
                    <div className="font-bold font-prompt text-lg pt-6 pb-1">{fields.teammember?.title}</div>
                    <Link
                      className="font-prompt text-lg font-light leading-relaxed hover:underline"
                      to={fields.teammember?.teammembercontent?.mail?.url}
                    >
                      {fields.teammember?.teammembercontent?.mail?.title}
                    </Link>
                    <Link
                      className="font-prompt text-lg font-light leading-relaxed hover:underline"
                      to={fields.teammember?.teammembercontent?.phone?.url}
                    >
                      {fields.teammember?.teammembercontent?.phone?.title}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="BannerDetailSubSection" className="container lg:hidden block pt-4">
        <div id="mobile-white-block" className="bg-white w-full shadow-feed top-0 right-0">
          <div className="flex flex-col gap-2 p-10 2xl:p-20 items-center justify-center">
            <Link
              className="button-alt flex gap-5 items-center"
              to={page.contentType?.node?.name === 'internalVacancy' ? `#Apply` : `#video-solliciteren`}
            >
              {page.contentType?.node?.name === 'internalVacancy' ? (
                <>Solliciteer nu</>
              ) : (
                <>
                  <svg width="33" height="24" viewBox="0 0 33 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M30.8151 3.6C29.6428 3.19187 28.3357 3.39687 27.3561 4.1425L25.864 5.22C25.1306 2.6325 22.958 0.665625 20.2459 0.133125L7.31192 0C3.27345 0 0 3.17437 0 7.09062V16.9094C0 20.8256 3.27345 24 7.31192 24H18.8118C22.1046 23.9956 24.9901 21.8612 25.864 18.7825L27.514 19.9706C28.1063 20.4013 28.8255 20.635 29.5654 20.6375C30.2892 20.6338 30.9943 20.4106 31.5808 20C32.5005 19.3213 33.0277 18.255 32.9981 17.1344V6.7025C33.0013 5.33 32.1338 4.0975 30.8145 3.6H30.8151ZM21.9989 16.9094C21.9963 18.6156 20.5713 19.9981 18.8118 20H7.31192C5.55242 19.9975 4.12677 18.6156 4.12484 16.9094V7.09062C4.12742 5.38437 5.55242 4.00187 7.31192 4H18.8118C20.5713 4.0025 21.997 5.38437 21.9989 7.09062V16.9094Z"
                      fill="white"
                    />
                  </svg>
                  Solliciteer via video
                </>
              )}
            </Link>
            <div className="font-nord text-xl">of</div>
            <Link className="button-alt-outline" to="/contact/">
              Kom eens kennis maken
            </Link>
          </div>
          <div className="grid grid-cols-11 gap-4 xl:gap-8 xl:px-8">
            <div className="col-span-5 overflow-hidden relative">
              <LossePlaatjie
                maxwidth={850}
                className="relative z-20"
                src={fields.teammember?.teammembercontent?.image}
                alt="person-image"
              />
              <svg
                className="absolute z-10 top-0 right-0"
                width="188"
                height="250"
                viewBox="0 0 188 250"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M129.255 0H188L62.0289 250H0L129.255 0Z" fill="#D0DEBB" />
              </svg>

              {/* <div className="bg-sprout w-[40px] h-[300px] rotate-[30deg] absolute -bottom-[30px] left-1/2" /> */}
            </div>
            <div className="col-span-6 flex justify-center flex-col">
              <Content className="content">{fields.teammember?.teammembercontent?.description}</Content>
              <div className="font-bold font-prompt text-lg pt-6 pb-1">{fields.teammember?.title}</div>
              <Link
                className="font-prompt text-lg font-light leading-relaxed hover:underline"
                to={fields.teammember?.teammembercontent?.mail?.url}
              >
                {fields.teammember?.teammembercontent?.mail?.title}
              </Link>
              <Link
                className="font-prompt text-lg font-light leading-relaxed hover:underline"
                to={fields.teammember?.teammembercontent?.phone?.url}
              >
                {fields.teammember?.teammembercontent?.phone?.title}
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
