import React from 'react'
import { useLoaderData, LosseLink as Link } from '@ubo/losse-sjedel'
import type { HeaderLoaderData } from './HeaderMenu'

const HeaderTop: React.FC = () => {
  const { header } = useLoaderData<HeaderLoaderData>()

  return (
    <ul className="mb-0 h-full w-full list-none lg:items-center justify-center gap-2 lg:gap-10 xl:gap-20 lg:flex-row flex-col flex">
      {header?.topmenu?.map((l, index: number) => (
        <Link
          className="hover:underline font-medium font-prompt text-lg lg:text-base lg:font-nord inline-flex items-center"
          to={l?.link?.url}
          key={index}
        >
          <span>{l?.link?.title}</span>
          <svg className="lg:hidden ml-2" width="5" height="8" viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 7L4 4L1 1" stroke="#222222" />
          </svg>
        </Link>
      ))}
    </ul>
  )
}

export default HeaderTop
