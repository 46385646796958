import { useNavigate } from '@ubo/losse-sjedel'

export default function OpenApplyForm() {
  const navigate = useNavigate()

  const script = document.createElement('script')
  script.src = 'https://yesway.recruitnowcockpit.nl/widgets/enrollment/recruitnow-open-enrollment-widget.js'
  script.async = true
  document.body.appendChild(script)

  // <script src="https://<klant>.recruitnowcockpit.nl/widgets/enrollment/recruitnow-open-enrollment-widget.js">
  return (
    <div
      onClick={() => navigate('#')}
      className="fixed cursor-pointer w-screen h-screen top-0 left-0 bg-mine-shaft z-40 bg-opacity-50 flex justify-center items-center"
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="pt-5 cursor-default relative bg-white max-w-[600px] max-h-[90vh] overflow-y-scroll lg:p-10 p-5"
      >
        <svg
          onClick={() => navigate('#')}
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          aria-hidden="true"
          role="img"
          className="absolute cursor-pointer top-5 right-5 iconify iconify--material-symbols"
          width="32"
          height="32"
          preserveAspectRatio="xMidYMid meet"
          viewBox="0 0 24 24"
        >
          <path fill="currentColor" d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6Z"></path>
        </svg>
        {/* @ts-ignore */}
        <recruitnow-open-enrollment-widget
          api-base-url="https://yesway.recruitnowcockpit.nl"
          open-enrollment-form-id="CandidateEnrollmentForms-1-A"
          // utm-tags="utm_source=facebook&utm_medium=social"
          submit-success="location.href = '/inschrijven-bedankt/';"
          submit-fail="location.href = '/404/';"
        />
      </div>
    </div>
  )
}
