import { useState, useRef, useEffect } from 'react'

export default function NotSoEndlessContainer({ ...props }) {
  const [containerWidth, setContainerWidth] = useState<number | null>(null)
  const containerRef = useRef<HTMLDivElement>(null)

  const handleResize = () => {
    if (!containerRef.current) {
      return
    }

    const width = containerRef.current.getBoundingClientRect().width
    const spaceLeft = (window.innerWidth - width) / 2

    setContainerWidth(Math.round(width + spaceLeft))
  }

  useEffect(() => {
    handleResize()

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', () => handleResize)
    }
  }, [containerRef])

  return (
    <>
      <div ref={containerRef} />
      <div
        className="ml-auto sm:pl-8 relative max-w-[1800px]"
        style={{
          width: containerWidth || '100%'
        }}
      >
        {props.children}
      </div>
    </>
  )
}
