import { LossePlaatjie } from '@ubo/losse-sjedel'
import { Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import type { Page_Flexcontent_Flex_Misc } from '~/graphql/types'

export default function Slider({ fields }: { fields: Page_Flexcontent_Flex_Misc }) {
  return (
    <section id="Slider">
      <div className="container py-10">
        <Swiper
          loop
          navigation
          modules={[Navigation]}
          breakpoints={{
            0: {
              slidesPerView: 1,
              spaceBetween: 30
            },
            1200: {
              slidesPerView: 3,
              spaceBetween: 30
            }
          }}
        >
          {fields?.slider?.map((item, index) => (
            <SwiperSlide key={index}>
              <LossePlaatjie src={item} maxwidth={2000} className="object-cover h-[310px]" />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  )
}
