import type { Page_Flexcontent_Flex_Posts, Branche } from '~/graphql/types'
import { LossePlaatjie as Image } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'
// import Content from '~/components/elements/Content'

export default function FlippableBranches({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  return (
    <section id="FlippableBranches">
      <div className="container flex flex-col gap-6 lg:gap-12 py-10 md:py-16 lg:py-24">
        <div>
          <Content className="content">{fields.description}</Content>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-5 gap-1 md:gap-6">
          {fields.posts?.edges?.map((post, index) => {
            const node = post?.node as Branche

            return (
              <div
                key={index}
                className="col-span-1 relative group flex flex-col justify-center md:justify-end items-center aspect-square bg-sprout"
              >
                {/* <div className="absolute opacity-0 group-hover:opacity-100 pointer-events-none group-hover:pointer-events-auto transition-all duration-200 h-full px-4 w-full bg-sprout top-0 left-0 flex flex-col justify-center items-center">
                  <svg
                    className="absolute pointer-events-none w-full h-full -right-14 top-0"
                    width="198"
                    height="293"
                    viewBox="0 0 198 293"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M136.479 0H197.279L66.9006 292.746H2.70148L136.479 0Z" fill="white" fillOpacity="0.2" />
                    <path d="M56.7518 0H0V176.868H56.7518V0Z" fill="white" fillOpacity="0.2" />
                  </svg>

                  <div className="text-xl font-nord font-medium pb-5">{node.title}</div>
                  <Content>{node.recap?.description}</Content>
                </div> */}

                <Image className="w-[70px] h-[70px] md:w-[120px] md:h-[120px]" src={node.recap?.image} alt="branche-icon" />
                <div className="md:h-[60px] mt-3 md:my-6 flex items-center flex-col justify-between">
                  <div className="font-nord font-medium text-base md:text-2xl leading-tight text-center">{node.title}</div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}
