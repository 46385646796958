import { LosseLink } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Banner } from '~/graphql/types'

export default function BannerContainerized({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  return (
    <section data-component="BannerContainerized" className="section">
      <div className="container">
        <div className="max-w-[1070px] mx-auto">
          <div className="bg-sprout shadow-feed px-7 md:px-11 lg:pb-0 pb-5 md:pb-8">
            <div className="flex flex-col lg:flex-row gap-6 lg:gap-10">
              <div className="flex flex-row md:gap-8 gap-5 lg:gap-10">
                <div>
                  <div className="flex w-14 md:w-20 h-20 md:h-32 justify-center items-center bg-outrageous-orange">
                    <svg
                      className="md:w-[47px] w-[29px]"
                      width="47"
                      height="62"
                      viewBox="0 0 47 62"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M32.6139 0.572998H46.7835L16.3983 61.0357H1.43649L32.6139 0.572998Z" fill="white" />
                      <path d="M14.0329 0.572998H0.806641V37.1026H14.0329V0.572998Z" fill="white" />
                    </svg>
                  </div>
                </div>
                <Content className="content-sm md:pt-8 pt-5 pb-0 lg:pt-10 lg:pb-10">{fields.description}</Content>
              </div>
              <div className="flex items-center justify-center">
                <LosseLink src={fields.link} className="button-alt-outline" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
