import type { Page_Flexcontent_Flex_Text } from '~/graphql/types'
import { LosseContent as Content, LosseLink } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import { useState } from 'react'
import Form from '~/components/elements/Form'
import Loading from '~/components/elements/Loading'

export default function Quote({ fields }: { fields: Page_Flexcontent_Flex_Text }) {
  const [show, setShow] = useState(false)


  return (
    <section id="Quote" className="overflow-hidden section">
      <div className="container">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-16 xl:gap-40">
          <div className="col-span-1 flex flex-col lg:gap-10">
            <Content className="content">{fields.description}</Content>
            {(fields.links?.length || 0) >= 1 && (
              <div className="flex xl:flex-row flex-col gap-3 lg:items-start items-center xl:justify-start justify-center max-lg:mt-7">
                {fields.links?.map((item, index) => {

                  if (item?.link?.title === "Download de brochure") {

                    return (
                      show ? (
                        <Form
                          className="form-alternative-button"
                          data={fields?.form}
                          privacyUrl="/privacy"
                          privacyUrlPlacement="privacy"
                          renderLoading={() => <Loading />}
                          renderStatus={({ status, content }) => <Content>{content}</Content>}
                        />
                      ) : (
                        <div onClick={() => setShow(true)} className="button-alt cursor-pointer" key={index}>
                          Download brochure
                        </div>
                      )
                    )
                  }

                  return (
                    <LosseLink src={item?.link} key={index} className={clsx(index === 0 && 'button-alt', index === 1 && 'button')} />
                  )
                })}
              </div>
            )}

          </div>
          <div className="col-span-1 py-14 relative flex justify-center items-center">
            <svg
              className="absolute h-[300px] lg:h-[700px] w-auto top-0 left-0 bottom-0 right-0 m-auto"
              width="556"
              height="730"
              viewBox="0 0 556 730"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M384.549 0H555.627L188.77 730H8.12744L384.549 0Z" fill="#222222" fillOpacity="0.05" />
              <path d="M160.211 0H0.523438V441.042H160.211V0Z" fill="#222222" fillOpacity="0.05" />
            </svg>

            <Content className="children-p:text-mine-shaft children-p:text-3xl sm:children-p:text-4xl md:children-p:text-5xl xl:children-p:text-5xl children-h1:text-3xl md:children-h1:text-5xl lg:children-h1:text-7xl children-h1:!leading-normal children-p:!leading-normal lg:children-p:!leading-tight children-p:!font-medium children-p:font-nord">
              {fields.quote}
            </Content>
          </div>
        </div>
      </div>
    </section>
  )
}
