import Content from '~/components/elements/Content'
import { LossePlaatjie, LosseLink } from '@ubo/losse-sjedel'
import type { InternalVacancy, Page_Flexcontent_Flex_Posts } from '~/graphql/types'
import InternalVacancyPreview from '~/components/elements/InternalVacancyPreview'

export default function InternalVacancies({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  return (
    <section id="InternalVacancies" className="overflow-hidden max-w-[100vw]">
      <div className="container">
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 py-5 md:py-10 lg:py-24">
          <div className="col-span-1">
            <Content className="content">{fields.description}</Content>
          </div>
          <div className="col-span-1 flex justify-center lg:justify-end items-center">
            <div className={`grid max-w-[400px] grid-cols-11 gap-8`}>
              <div className="col-span-5 overflow-hidden relative">
                <LossePlaatjie maxwidth={500} className="relative z-20" src={fields.person?.teammembercontent?.image} alt="person-image" />

                {/* <div className="bg-sprout w-[40px] h-[300px] rotate-[30deg] absolute -bottom-[30px] left-1/2" /> */}
              </div>
              <div className="col-span-6 flex justify-center flex-col">
                <Content className="content">{fields.person?.teammembercontent?.description}</Content>
                <div className="font-bold font-prompt text-xl pt-6 pb-1">{fields.person?.title}</div>
                <LosseLink
                  className="font-prompt text-lg font-light leading-relaxed hover:underline"
                  to={fields.person?.teammembercontent?.mail?.url}
                >
                  {fields.person?.teammembercontent?.mail?.title}
                </LosseLink>
                <LosseLink
                  className="font-prompt text-lg font-light leading-relaxed hover:underline"
                  to={fields.person?.teammembercontent?.phone?.url}
                >
                  {fields.person?.teammembercontent?.phone?.title}
                </LosseLink>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="relative">
        <div className="container pb-10 md:pb-20 lg:pb-40">
          <div className="py-5 md:py-10 lg:py-20">
            <Content className="content">{fields.seconddescription}</Content>
          </div>
          <div className="flex flex-col gap-12 lg:px-10 xl:px-40">
            {fields.posts?.edges?.length === 0 && (
              <div className="flex flex-col gap-6">
                <div className="text-center font-medium font-prompt">
                  Helaas hebben wij nog geen interne vacatures openstaan! <br /> Wil je wel alvast kennis maken?
                </div>
                <div className="flex justify-center">
                  <LosseLink className="button-alt" to="/contact">
                    Neem contact op
                  </LosseLink>
                </div>
              </div>
            )}
            {fields.posts?.edges?.map((post: any, index: number) => {
              const node = post?.node as InternalVacancy

              return <InternalVacancyPreview item={node} key={index} />
            })}
          </div>
        </div>
        <svg
          className="w-screen -z-10 mx-auto top-20 left-0 absolute"
          width="1728"
          height="2787"
          viewBox="0 0 1728 2787"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M1465.99 0H2119.06L718.615 2786.71H29.0273L1465.99 0Z" fill="#222222" fillOpacity="0.05" />
          <path d="M609.594 0H0V1683.64H609.594V0Z" fill="#222222" fillOpacity="0.05" />
        </svg>
      </div>
    </section>
  )
}
