import React from 'react'
import HeaderMenu from './HeaderMenu'
import { useLoaderData } from '@ubo/losse-sjedel'
import { LosseLink as Link } from '@ubo/losse-sjedel'
import type { HeaderLoaderData } from './Header'

const HeaderDesktop: React.FC = () => {
  const { header } = useLoaderData<HeaderLoaderData>()

  return (
    <>
      <ul className="mb-0 py-4 2xl:py-6 h-full hidden w-full list-none items-start justify-evenly lg:flex">
        <HeaderMenu />
      </ul>
      <Link
        className="font-nord lg:flex hidden 2xl:text-base text-sm self-start items-center gap-3 leading-tight text-right font-medium bg-sprout text-outrageous-orange rounded-b-[21px] px-5 pt-2 pb-3"
        to={header.other?.whatsapp?.url}
        target="_blank"
      >
        {header.other?.whatsapp?.title}
        <svg className="min-w-[35px] h-[35px]" width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M22.3354 12.6644L22.3009 12.6299C21.1563 11.4914 19.6438 10.7976 18.0342 10.6728C16.4247 10.548 14.8233 11.0003 13.5169 11.9487C12.2105 12.8972 11.2845 14.2797 10.9046 15.8488C10.5248 17.4178 10.716 19.0708 11.444 20.5117L11.7434 21.1012L11.1838 23.8137V23.8482L13.871 23.2104L14.4835 23.5144C15.7583 24.1471 17.1994 24.3639 18.604 24.1342C20.0085 23.9046 21.3056 23.24 22.3124 22.2341C23.5765 20.9639 24.288 19.246 24.2924 17.454C24.2967 15.662 23.5934 13.9407 22.3354 12.6644ZM22.0637 20.2631C21.7344 20.7811 21.2117 21.4144 20.5601 21.5732C19.4088 21.8519 17.6565 21.5732 15.4621 19.5446L15.4345 19.5193C13.5256 17.7371 13.019 16.2519 13.1318 15.0753C13.1986 14.4075 13.7558 13.8042 14.2256 13.4082C14.3001 13.3447 14.3885 13.2997 14.4836 13.2767C14.5787 13.2537 14.6779 13.2534 14.7731 13.2758C14.8684 13.2982 14.957 13.3427 15.0319 13.4057C15.1068 13.4687 15.1658 13.5484 15.2042 13.6384L15.9111 15.2503C15.9572 15.3531 15.9746 15.4666 15.9612 15.5785C15.9478 15.6905 15.9042 15.7967 15.8351 15.8858L15.4759 16.3463C15.4012 16.4419 15.3568 16.5576 15.3482 16.6786C15.3395 16.7996 15.3672 16.9204 15.4275 17.0256C15.7689 17.5277 16.1772 17.9809 16.641 18.3726C17.139 18.8545 17.7054 19.2603 18.3219 19.5769C18.4342 19.6225 18.5576 19.6334 18.6761 19.6084C18.7947 19.5834 18.9031 19.5235 18.9874 19.4364L19.3996 19.0196C19.4765 18.9315 19.5766 18.8666 19.6885 18.8324C19.8004 18.7982 19.9197 18.7961 20.0328 18.8262L21.7137 19.3098C21.8071 19.3371 21.893 19.3858 21.9644 19.452C22.0358 19.5181 22.0909 19.6 22.1252 19.6911C22.1596 19.7822 22.1724 19.88 22.1625 19.9769C22.1526 20.0737 22.1204 20.167 22.0683 20.2492L22.0637 20.2631Z"
            fill="#FF5C39"
          />
          <path
            d="M17.4999 0C14.0388 0 10.6553 1.02635 7.77749 2.94927C4.89964 4.87218 2.65663 7.60529 1.33211 10.803C0.00758239 14.0007 -0.338974 17.5193 0.336264 20.914C1.0115 24.3086 2.67821 27.4268 5.12561 29.8742C7.57302 32.3216 10.6912 33.9883 14.0858 34.6636C17.4805 35.3388 20.9991 34.9922 24.1968 33.6677C27.3945 32.3432 30.1276 30.1002 32.0505 27.2223C33.9735 24.3445 34.9998 20.9611 34.9998 17.4999C34.9998 12.8586 33.1561 8.40747 29.8742 5.1256C26.5923 1.84373 22.1412 0 17.4999 0V0ZM26.1739 17.7048C26.1266 19.1582 25.7151 20.5764 24.9771 21.8294C24.2391 23.0823 23.1982 24.1299 21.9501 24.8759C20.7019 25.6219 19.2863 26.0425 17.8333 26.0991C16.3802 26.1557 14.9363 25.8465 13.6338 25.1999L9.25884 26.2406C9.19269 26.2558 9.12351 26.2508 9.06028 26.2261C8.99705 26.2015 8.94268 26.1584 8.90423 26.1025C8.87795 26.0651 8.85968 26.0227 8.85057 25.9779C8.84146 25.9331 8.8417 25.887 8.85127 25.8423L9.77232 21.3706C9.17027 20.1909 8.84612 18.8891 8.8247 17.5649C8.80328 16.2406 9.08516 14.929 9.64874 13.7305C10.2123 12.5319 11.0427 11.4783 12.0762 10.6501C13.1098 9.82192 14.3192 9.24122 15.6117 8.95245C16.9043 8.66368 18.2458 8.6745 19.5335 8.98408C20.8213 9.29366 22.0211 9.89378 23.0412 10.7385C24.0612 11.5832 24.8745 12.6502 25.4187 13.8577C25.9629 15.0651 26.2236 16.3811 26.1808 17.7048H26.1739Z"
            fill="#FF5C39"
          />
        </svg>
      </Link>
    </>
  )
}

export default HeaderDesktop
