import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Banner } from '~/graphql/types'
import { LossePlaatjie as Image } from '@ubo/losse-sjedel'

export default function BannerImages({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  return (
    <section id="BannerImages" className="relative">
      <div className="bg-outrageous-orange w-full absolute top-0 left-0 h-[130px] lg:h-[200px] 2xl:h-[240px]" />
      <div className="container relative">
        <div className="py-5 sm:py-10 lg:py-16 relative">
          <div className="relative flex justify-center flex-wrap md:grid md:grid-cols-[repeat(17,_minmax(0,_1fr))]">
            <div className="w-full md:col-span-10 relative">
              <Image maxwidth={1000} className="max-h-[380px]" loading="eager" src={fields.images![0]?.image} alt="banner-images-image-1" />
              {/* <Content className="absolute children-h1:text-3xl md:children-h1:text-4xl lg:children-h1:text-8xl text-center md:top-0 bottom-1 md:bottom-0 left-0 right-0 md:left-[unset] md:right-2 flex justify-center items-center text-white text-shadow"> */}
              <Content className="absolute content md:top-0 bottom-1 md:bottom-0 left-0 right-0 md:left-[unset] md:right-2 text-center flex justify-center items-center children-h1:text-shadow">
                {fields.images![0]?.description}
              </Content>
            </div>
            <div className="w-[70%] md:w-[unset] md:col-span-7 relative flex items-center">
              <Image maxwidth={1000} className="max-h-[270px]" loading="eager" src={fields.images![1]?.image} alt="banner-images-image-1" />
              <Content className="absolute content top-1 md:top-0 md:bottom-0 right-0 left-0 md:right-[unset] md:left-2 flex justify-center items-center children-h1:text-shadow">
                {fields.images![1]?.description}
              </Content>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
