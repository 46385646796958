import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Text } from '~/graphql/types'

export default function TextWithImages({ fields }: { fields: Page_Flexcontent_Flex_Text }) {
  return (
    <section data-component="TextWithImages" className="section">
      <div className="container">
        <div className="grid grid-cols-1 gap-10 lg:gap-20">
          {fields?.images?.length && (
            <div className="flex items-center lg:max-w-screen-xl lg:mx-auto">
              <div className="flex justify-center flex-wrap lg:grid lg:grid-cols-[repeat(17,_minmax(0,_1fr))]">
                {fields.images?.map((item, index) => (
                  <div
                    key={index}
                    className={`${
                      index === 1
                        ? `${fields.switchimages ? `col-span-10` : `w-[70%] lg:w-[unset] col-span-7`}`
                        : `${!fields.switchimages ? `col-span-10` : `w-[70%] lg:w-[unset] col-span-7`}`
                    } group relative flex items-center`}
                  >
                    <div className="relative z-10 w-full">
                      {!fields.switchimages && index === 1 && (
                        <div
                          className={clsx(
                            'absolute h-full top-0 w-full max-lg:h-[90px] lg:w-[90px] from-mine-shaft to-transparent max-lg:top-0 lg:left-0 max-lg:bg-gradient-to-b lg:bg-gradient-to-r'
                          )}
                        />
                      )}

                      {fields.switchimages && index === 0 && (
                        <div
                          className={clsx(
                            'absolute h-full lg:top-0 max-lg:left-0 w-full max-lg:h-[90px] lg:w-[90px] from-mine-shaft to-transparent bottom-0 lg:right-0 max-lg:bg-gradient-to-t lg:bg-gradient-to-l'
                          )}
                        />
                      )}

                      <LossePlaatjie maxwidth={1000} autoheight={false} className="w-full" src={item?.image} alt="block-image" />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          <div className={clsx('lg:max-w-screen-lg lg:mx-auto')}>
            <div className="flex flex-col gap-6 lg:gap-10">
              <Content className="content lg:text-center lg:children-headings:!text-center">{fields?.description}</Content>
              {(fields.links?.length || 0) >= 1 && (
                <div className="flex xl:flex-row flex-col gap-3 lg:items-center items-center justify-center">
                  {fields.links?.map((item, index) => (
                    <LosseLink src={item?.link} key={index} className={clsx(index === 0 && 'button-alt', index === 1 && 'button')} />
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
