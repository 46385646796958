import React, { useEffect, useState } from 'react'
import { LosseLayout, useLocation } from '@ubo/losse-sjedel'
import Footer from './Footer'
import Header from './Header'
import Kennismaken from '../elements/Kennismaken.client'

export default function Layout({ children }: React.PropsWithChildren<{}>) {
  const location = useLocation()
  const [showModal, setShowModal] = useState({
    kennismaken: false
  })

  useEffect(() => {
    const { documentElement } = document

    if (location.hash.includes('kennismaken')) {
      documentElement.classList.add('overflow-hidden')
      setShowModal({
        kennismaken: true
      })
    } else {
      documentElement.classList.remove('overflow-hidden')
      setShowModal({
        kennismaken: false
      })
    }
  }, [location])

  return (
    <LosseLayout>
      <Header />
      {showModal.kennismaken && <Kennismaken />}
      <main className="min-h-screen pt-[90px] lg:pt-[140px] 2xl:pt-[150px]">{children}</main>
      <Footer key="footer" />
    </LosseLayout>
  )
}
