import type { Page_Flexcontent_Flex_Text } from '~/graphql/types'
import { LosseContent as Content } from '@ubo/losse-sjedel'

export default function Extra({ fields }: { fields: Page_Flexcontent_Flex_Text }) {
  return (
    <section id="Extra">
      <div className="container py-5 md:py-10 lg:py-20">
        <div className="grid grid-cols-3 gap-32">
          <div id="video-solliciteren" className="col-span-3 lg:col-span-2">
            <Content className="children-iframe:mx-auto content">{fields.description}</Content>
          </div>
        </div>
      </div>
    </section>
  )
}
