import FeedPreview from '~/components/elements/FeedPreview'
import type { Page_Flexcontent_Flex_Posts, Post } from '~/graphql/types'
import useMediaQuery from '~/hooks/useMediaQuery'

export default function Posts({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  let hitIndex = 0
  const isMobile = useMediaQuery(`(max-width: 768px)`)

  return (
    <section id="Posts">
      <div className="container py-8 md:py-14 lg:py-20">
        <div className="grid grid-cols-2 s:grid-cols-4 md:grid-cols-5 auto-rows-auto xl:px-32 gap-4 lg:gap-7">
          {fields.posts?.edges?.map((post, index) => {
            const node = post?.node as Post

            if (index === 10) {
              hitIndex += 10
            }

            const hit = [0, 3, 6, 8].includes(index - hitIndex)

            return (
              <div className={`${hit ? `row-span-2 md:row-span-3 col-span-2 md:col-span-3` : `row-span-2 col-span-2`}`} key={index}>
                <FeedPreview size={hit ? `${isMobile ? `big` : `bigger`}` : 'big'} fields={node} />
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}
