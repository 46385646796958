import type { Page_Flexcontent_Flex_Text } from '~/graphql/types'
import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'
import clsx from 'clsx'

export default function USP({ fields }: { fields: Page_Flexcontent_Flex_Text }) {
  return (
    <section id="USP" className="section">
      <div className="container">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 lg:gap-16">
          {fields.mirrored && (
            <div className="col-span-1 flex flex-col lg:gap-10">
              <Content className="content">{fields.description}</Content>
              {(fields.links?.length || 0) >= 1 && (
                <div className="flex xl:flex-row max-lg:mt-7 flex-col gap-3 lg:items-start items-center xl:justify-start justify-center">
                  {fields.links?.map((item, index) => (
                    <LosseLink src={item?.link} key={index} className={clsx(index === 0 && 'button-alt', index === 1 && 'button')} />
                  ))}
                </div>
              )}
            </div>
          )}
          <div className="col-span-1 flex justify-center">
            <div className="flex flex-col gap-5 lg:gap-8 lg:pt-16">
              {fields.usps?.map((usp, index) => (
                <div className="flex items-center gap-5 md:gap-10" key={index}>
                  <div className="bg-outrageous-orange flex justify-center items-center min-w-[55px] sm:min-w-[70px] md:min-w-[100px] lg:min-w-[125px] aspect-square rounded-full">
                    <LossePlaatjie className="w-[30px] sm:w-[40px] md:w-[50px] lg:w-[70px] lg:h-[63px]" src={usp?.icon} alt="usp-icon" />
                  </div>
                  <Content className="content">{usp?.description}</Content>
                </div>
              ))}
            </div>
          </div>
          {!fields.mirrored && (
            <div className="col-span-1 flex flex-col lg:gap-10">
              <Content className="content">{fields.description}</Content>
              {(fields.links?.length || 0) >= 1 && (
                <div className="flex xl:flex-row max-lg:mt-7 flex-col gap-3 lg:items-start items-center xl:justify-start justify-center">
                  {fields.links?.map((item, index) => (
                    <LosseLink src={item?.link} key={index} className={clsx(index === 0 && 'button-alt', index === 1 && 'button')} />
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </section>
  )
}
