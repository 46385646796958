import { useState } from 'react'
import type { Page_Flexcontent_Flex_Posts } from '~/graphql/types'
import { LossePlaatjie as Image, LosseContent as Content } from '@ubo/losse-sjedel'
import { AnimatePresence, motion } from 'framer-motion'

export default function Testimonials({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  const [currentIndex, setIndex] = useState(0)

  return (
    <section id="Testimonials">
      <div className="container py-10 md:py-16 lg:py-24">
        <div className="max-w-screen-xl mx-auto flex justify-end relative lg:pb-0 pb-4 xl:-bottom-2">
          <Content className="children-h2:leading-normal content">{fields.description}</Content>
        </div>
        <div className="flex justify-center flex-wrap lg:grid lg:grid-cols-[repeat(17,_minmax(0,_1fr))] xl:px-20">
          <div className="w-full col-span-10 h-[280px] xs:h-[310px] s:h-[380px] sm:h-[440px] md:h-[500px] lg:h-[480px] 2xl:h-[580px] overflow-hidden relative">
            <AnimatePresence>
              <motion.div
                id={`content-testi-${currentIndex}`}
                key={`image-testi-${currentIndex}`}
                initial={{ x: '100%' }}
                animate={{ x: 0 }}
                exit={{ x: '-100%' }}
                transition={{ duration: 0.5 }}
                className="absolute h-full w-full"
              >
                <Image
                  maxwidth={2000}
                  className="h-full object-cover"
                  src={fields.highlightedtestimonials![currentIndex]?.testimonial?.image}
                  alt="testimonial-image"
                />
              </motion.div>
            </AnimatePresence>
          </div>
          <div className="col-span-7 relative flex justify-center items-center">
            <div className="bg-sprout aspect-video p-4 lg:h-[70%] w-[70%] lg:w-full flex justify-center items-center">
              <AnimatePresence mode="wait">
                <motion.div
                  id={`content-testi-${currentIndex}`}
                  key={`content-testi-${currentIndex}`}
                  initial={{ y: -20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  exit={{ y: 20, opacity: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  <Content className="content-lg">{fields.highlightedtestimonials![currentIndex]?.testimonial?.quote}</Content>
                </motion.div>
              </AnimatePresence>
              {(fields.highlightedtestimonials?.length || 0) > 1 && (
                <div className="absolute -top-16 lg:top-[unset] lg:bottom-5 right-5 flex gap-2 lg:gap-4">
                  <svg
                    className="rotate-180 w-[40px] lg:w-[50px] group cursor-pointer"
                    onClick={() => (currentIndex === 0 ? setIndex(fields.highlightedtestimonials!.length - 1) : setIndex(currentIndex - 1))}
                    width="52"
                    height="52"
                    viewBox="0 0 52 52"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle className="group-hover:fill-sprout transition-all duration-200" cx="26" cy="26" r="26" fill="#FF5C39" />
                    <path d="M27.7027 25.7765L17 7H24.2973L35 25.7765L24.2973 45H17L27.7027 25.7765Z" fill="white" />
                  </svg>
                  <svg
                    className="group w-[40px] lg:w-[50px] cursor-pointer"
                    onClick={() => (currentIndex + 1 === fields.highlightedtestimonials?.length ? setIndex(0) : setIndex(currentIndex + 1))}
                    width="52"
                    height="52"
                    viewBox="0 0 52 52"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle className="group-hover:fill-sprout transition-all duration-200" cx="26" cy="26" r="26" fill="#FF5C39" />
                    <path d="M27.7027 25.7765L17 7H24.2973L35 25.7765L24.2973 45H17L27.7027 25.7765Z" fill="white" />
                  </svg>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
