import Preview from '~/components/elements/Preview'
import type { Assignment, Page_Flexcontent_Flex_Posts, Vacancy } from '~/graphql/types'
import { LosseContent as Content, type LoaderData } from '@ubo/losse-sjedel'
import { LossePlaatjie as Image, useLoaderData, LosseLink as Link } from '@ubo/losse-sjedel'
import { useEffect, useState } from 'react'

interface AssigmentNode {
  node: Assignment | Vacancy
}

export default function Auto({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  const { page } = useLoaderData<LoaderData>()
  const [filteredPosts, setFilteredPosts] = useState<AssigmentNode[]>([])

  useEffect(() => {
    const edges = fields.posts?.edges as AssigmentNode[]

    // @ts-ignore
    const posts = edges.filter((post) => (post?.node?.data.branche !== null && post?.node?.data.branche.databaseId) === page.databaseId)
    setFilteredPosts(posts)
  }, [fields.posts?.edges, page.databaseId])

  return (
    <section id="Auto">
      <div className="container py-10 md:py-16 lg:py-24">
        <div className="grid grid-cols-2 lg:grid-cols-3 gap-10 lg:gap-16 xl:gap-24">
          <div className="col-span-2 lg:col-span-1 flex items-center">
            <div className="flex flex-col gap-10 xl:gap-16">
              <Content className="content">{fields.description?.replace('{branche}', page.title || 'yesway')}</Content>
              <Link to="/#Apply" className="flex flex-nowrap lg:flex-wrap xl:flex-nowrap items-center gap-6">
                <div className="flex items-center justify-center bg-sprout aspect-square min-w-[90px] xl:min-w-[125px] p-7 rounded-full">
                  <Image className="w-full" src={fields.icon} alt="video-icon" />
                </div>
                <Content className="content">{fields.videodescription}</Content>
              </Link>
              <Link
                className="button-alt max-w-[320px] text-center"
                to={fields.description?.includes('Vacatures') ? `/vacatures/` : `/opdrachten/`}
              >
                {fields.loadmoretext}
              </Link>
            </div>
          </div>
          <div className="col-span-2">
            <h2 className="font-nord font-medium text-3xl md:text-4xl lg:text-5xl">
              <span className="text-sprout">Uitgelichte</span>
              {` `}
              <span className="text-outrageous-orange">{fields.description?.includes('Vacatures') ? `vacatures` : `opdrachten`}</span>
            </h2>
            <div className="flex flex-col gap-10 pt-10">
              {filteredPosts.length === 0 && <div className="font-prompt">Er zijn voor deze branche helaas nog geen vacatures</div>}
              {filteredPosts.slice(0, 3).map((post, index) => {
                const node = post

                return <Preview key={index} item={node.node} readmoretext={fields.readmoretext} />
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
