import React from 'react'
import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'

// Components
import TextDefault from './TextDefault'
import Detail from './Detail'
import Quote from './Quote'
import USP from './USP'
import Extra from './Extra'
import TextWithSlider from './TextWithSlider'
import TextWithCollapsables from './TextWithCollapsables'
import TextWithImages from './TextWithImages'

const TextShell: React.FC<LosseFlexShellProps> = ({ fields, index }) => {
  const types: LosseFlexShellType = {
    standard: TextDefault,
    usp: USP,
    quote: Quote,
    detail: Detail,
    extratext: Extra,
    textwithslider: TextWithSlider,
    textwithimages: TextWithImages,
    collapsables: TextWithCollapsables
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}

export default TextShell
