import { useLoaderData, LossePlaatjie as Image, LosseContent as Content, LosseLink as Link } from '@ubo/losse-sjedel'
import type { LoaderData } from '@ubo/losse-sjedel'
import type { Page_Flexcontent_Flex_Banner, Post } from '~/graphql/types'
import Share from '~/components/elements/Share'
import clsx from 'clsx'

// right-10

export default function BannerFeed({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  const { page } = useLoaderData<LoaderData<Post>>()

  return (
    <section id="BannerFeed">
      <div className="bg-outrageous-orange relative z-20 pt-5 xl:pt-32 pb-6 xl:pb-10">
        <div className="container">
          <div className="grid grid-cols-8">
            <div className="col-span-8 xl:col-span-2" />
            <div className="col-span-8 flex items-end xl:col-span-6 xl:px-32">
              <div className="relative xl:min-w-[160px]" />
              <div className="xl:pl-11 2xl:pl-40">
                <Link className="text-white font-medium font-nord text-lg md:text-xl hover:underline" to={fields.link?.url}>
                  {fields.link?.title}
                </Link>
                <div className="flex items-center gap-4 s:gap-8 md:gap-11 pt-4 md:pt-10">
                  <div>
                    <div className="bg-sprout px-7 py-2 font-nord font-medium text-mine-shaft text-lg md:text-xl uppercase">
                      {page.categories?.edges![0]?.node?.name}
                    </div>
                  </div>
                  <div className="flex gap-3 items-center font-nord font-medium text-xs s:text-lg md:text-xl text-white">
                    <svg
                      className="w-[20px] s:w-[32px]"
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16 0C7.16375 0 0 7.16375 0 16C0 24.8363 7.16375 32 16 32C24.8325 31.9906 31.9906 24.8325 32 16C32 7.16375 24.8363 0 16 0ZM16 28C9.3725 28 4 22.6275 4 16C4 9.3725 9.3725 4 16 4C22.6244 4.0075 27.9925 9.37563 28 16C28 22.6275 22.6275 28 16 28Z"
                        fill="white"
                      />
                      <path d="M13.9998 14.74L9.10547 17.8057L11.228 21.1944L17.9998 16.9532V8.36255H13.9998V14.74Z" fill="white" />
                    </svg>
                    {`Leestijd: ${page.recap?.time}`}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container pb-10 xl:pb-24">
        <div className="grid grid-cols-8">
          <div className="col-span-8 xl:col-span-2" />
          <div className="col-span-8 xl:col-span-6 xl:px-32 relative pt-8 xl:pt-4 pb-8 xl:pb-24 xl:shadow-feed">
            <div className="flex flex-wrap items-center lg:items-end justify-center md:flex-nowrap mb-10 gap-8 lg:gap-0 xl:mb-20">
              <div className="relative w-full xl:min-w-[160px]">
                <Image
                  maxwidth={600}
                  className="xl:absolute max-w-[unset] w-full object-cover max-h-[350px] md:max-h-[unset] md:w-[320px] xl:w-[450px] right-10 bottom-0 z-20"
                  src={page.recap?.image}
                  alt="feed-image"
                />
              </div>
              <h1 className="text-3xl md:text-3xl lg:text-5xl font-medium font-nord !leading-snug lg:min-w-[535px]">{page.title}</h1>
            </div>
            <div className="relative">
              <div className="absolute -left-56 -top-10">
                <Share />
              </div>
              {/* <Content className="mb-6 children-blockquote:pr-8 lg:children-blockquote:pr-16 children-blockquote:before:bg-sprout children-blockquote:relative children-blockquote:before:absolute children-blockquote:before:h-full children-blockquote:before:w-32 children-blockquote:before:-left-32 children-blockquote:before:top-0 children-h2:text-2xl lg:children-h2:text-3xl children-h2:pb-5 children-blockquote:py-10 lg:children-blockquote:py-16 children-p:children-blockquote:font-medium children-p:children-blockquote:font-nord children-blockquote:text-outrageous-orange children-p:children-blockquote:text-lg children-p:children-blockquote:leading-[28px]  lg:children-p:children-blockquote:text-2xl lg:children-p:children-blockquote:leading-[34px] children-blockquote:bg-sprout children-blockquote:my-4 lg:children-blockquote:my-8 children-a:text-outrageous-orange"> */}
              <Content className="content-xs">{fields.description}</Content>
              {fields?.links && (
                <div className="flex lg:flex-row  flex-col w-full gap-3 md:gap-6 justify-start">
                  {fields.links?.map((link, index) => (
                    <Link
                      key={index}
                      className={clsx(index === 0 && 'button-alt', index === 1 && 'button', 'px-4 !w-[unset] text-center')}
                      to={link?.link?.url || '/'}
                    >
                      {link?.link?.title}
                    </Link>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
