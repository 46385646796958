import { useLoaderData, LosseContent as Content } from '@ubo/losse-sjedel'
import type { Page_Flexcontent_Flex_Form } from '~/graphql/types'
import type { LoaderData } from '@ubo/losse-sjedel'
import type { Feed } from '~/types/declarations'
import { ClientOnly } from 'remix-utils'
import { lazy, Suspense } from 'react'

// @ts-ignore
const ApplyForm = lazy(() => import('~/components/elements/ApplyForm.client'))

export default function Apply({ fields }: { fields: Page_Flexcontent_Flex_Form }) {
  const { page } = useLoaderData<LoaderData>()
  // @ts-ignore
  const data = JSON.parse(page.data.data) as Feed.FeedObject

  return (
    <section id="Apply">
      <div className="container py-5 md:py-10 lg:py-16">
        <div className="grid grid-cols-3 lg:gap-32">
          <div className="col-span-3 lg:col-span-2">
            <Content className="content">{fields.description}</Content>
            <ClientOnly fallback={<p>Loading...</p>}>
              {() => (
                <Suspense fallback={<p>Loading...</p>}>
                  <ApplyForm data={data} />
                </Suspense>
              )}
            </ClientOnly>
          </div>
        </div>
      </div>
    </section>
  )
}
