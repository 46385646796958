import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import { motion } from 'framer-motion'
import { useState } from 'react'
import Content from '~/components/elements/Content'
import type { Maybe, Page_Flexcontent_Flex_Text, Page_Flexcontent_Flex_Text_Usps } from '~/graphql/types'

export default function TextWithCollapsables({ fields }: { fields: Page_Flexcontent_Flex_Text }) {
  return (
    <section data-component="TextWithCollapsables" className="section">
      <div className="container">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-20 lg:gap-10 xl:gap-20">
          <div className="flex flex-col gap-6 lg:gap-10">
            <Content className="content">{fields.description}</Content>
            {(fields.links?.length || 0) >= 1 && (
              <div className="flex xl:flex-row flex-col gap-3 lg:items-start items-center xl:justify-start justify-center">
                {fields.links?.map((item, index) => (
                  <LosseLink src={item?.link} key={index} className={clsx(index === 0 && 'button-alt', index === 1 && 'button')} />
                ))}
              </div>
            )}
          </div>
          <div className="flex items-center">
            <div className="flex max-w-screen-sm mx-auto flex-col justify-center gap-5 lg:gap-8">
              {fields.usps?.map((item, index) => (
                <Block item={item} key={index} index={index} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

function Block({ item, index }: { item: Maybe<Page_Flexcontent_Flex_Text_Usps>; index: number }) {
  const [open, setOpen] = useState(index === 0 ? true : false)

  return (
    <div className={clsx(open ? 'gap-3 lg:gap-5' : 'lg:gap-5', 'shadow-feed flex flex-col pt-3 lg:pt-5 lg:p-8 p-5')}>
      <div onClick={() => setOpen(!open)} className="flex gap-4 items-center">
        <div className="bg-sprout px-3 lg:px-5 relative">
          <div className="absolute bg-sprout w-full h-3 lg:h-5 bottom-full left-0" />
          <LossePlaatjie className="my-3 lg:my-4" src={item?.icon} alt="icon" />
        </div>
        <div className="flex justify-between items-center w-full">
          <Content className="content-xs">{item?.title}</Content>
          <svg
            className={clsx(!open && 'rotate-180', 'lg:hidden block smooth')}
            width="19"
            height="12"
            viewBox="0 0 19 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M2 10L9.5 3L17 10" stroke="#FF5C39" strokeWidth="3" />
          </svg>
        </div>
      </div>
      <Content className="content lg:block hidden">{item?.description}</Content>
      <motion.div className="overflow-hidden lg:hidden block" animate={{ height: open ? 'auto' : 0 }}>
        <Content className="content">{item?.description}</Content>
      </motion.div>
    </div>
  )
}
