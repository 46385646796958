import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Form } from '~/graphql/types'
import Loading from '~/components/elements/Loading'
import Form from '~/components/elements/Form'
import { FormDuufGroup, FormDuufSubmit } from '@ubo/form-duuf'

export default function FormAltContact({ fields }: { fields: Page_Flexcontent_Flex_Form }) {
  return (
    <section id="FormAltContact" className="overflow-hidden">
      <div className="container">
        <div className="grid grid-cols-2 lg:grid-cols-2 lg:gap-12 2xl:gap-32">
          <div className="col-span-2 pb-12 lg:py-12 2xl:py-20">
            <div className="bg-white shadow-feed px-6 s:px-16 xl:px-32 py-6 sm:py-12 xl:py-16">
              <div>
                <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
                  <div>
                    <Content className="content">{fields?.description}</Content>
                  </div>
                  <div>
                    <Content className="children-h3:!text-left lg:children-h3:!text-right children-strong:children-h3:text-base children-strong:children-h3:font-nord children-strong:children-h3:font-medium md:children-strong:children-h3:text-3xl children-a:underline">
                      {fields.intro}
                    </Content>
                  </div>
                </div>
                <div className="mt-5 lg:mt-10">
                  <Form
                    className="form-alt-contact"
                    data={fields.form}
                    captchaSiteKey="6LfZylIgAAAAAI80YNx6UcMFt1f2uBzJvRitDMoB"
                    privacyUrl="/privacy"
                    privacyUrlPlacement="privacy statement"
                    renderLoading={() => <Loading />}
                    renderStatus={({ content }) => <Content>{content}</Content>}
                    generate={false}
                  >
                    <div className="grid grid-cols-1 lg:gap-1 lg:grid-cols-1">
                      <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-12 gap-1">
                        <FormDuufGroup showIds={[1, 3]} />
                      </div>
                      <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-12 gap-1">
                        <FormDuufGroup showIds={[4, 5]} />
                      </div>
                      <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-12 gap-1">
                        <FormDuufGroup showIds={[6, 7]} />
                      </div>
                      <div className="grid grid-cols-1 lg:gap-12 gap-1">
                        <FormDuufGroup showIds={[8]} />
                      </div>
                      <div className="flex justify-between items-center">
                        <FormDuufGroup showIds={[9]} />
                        <FormDuufSubmit />
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
