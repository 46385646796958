import { useMatches } from '@ubo/losse-sjedel'
import { lazy, useRef, Suspense } from 'react'
import type { Page_Flexcontent_Flex_Misc } from '~/graphql/types'
import { ClientOnly } from 'remix-utils'
import clsx from 'clsx'

// @ts-ignore
const Vid = lazy(() => import('~/components/elements/Video.client'))

export default function Video({ fields }: { fields: Page_Flexcontent_Flex_Misc }) {
  const element = useRef<HTMLDivElement>(null)

  const matches = useMatches()
  const lighthouse = matches[0].data.isLighthouse

  return (
    <section ref={element} id="Video" className={clsx('section', fields?.noSpaceBottom && '!pb-0')}>
      <div className={clsx(fields.containerize ? 'container' : 'max-w-[2000px]', ' mx-auto')}>
        {!lighthouse ? (
          <ClientOnly fallback={<></>}>
            {() => (
              <Suspense fallback={<></>}>
                <Vid fields={fields} />
              </Suspense>
            )}
          </ClientOnly>
        ) : (
          <img
            src="https://i.vimeocdn.com/video/1444269044-a0bf6216eed73079032054eaf11d91465d312e2cfaf51e87048c4f0128fc70cf-d?mw=400&mh=250&q=75&format=webp"
            loading="eager"
            alt="yesway video"
          />
        )}
      </div>
    </section>
  )
}
