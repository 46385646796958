import { Link, LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { useSwiperSlide } from 'swiper/react'
import Content from '~/components/elements/Content'
import Slider, { Slide } from '~/components/elements/Slider'
import type { Maybe, Page_Flexcontent_Flex_Banner, Page_Flexcontent_Flex_Banner_Images } from '~/graphql/types'

export default function BannerHome({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  const [active, setActive] = useState(0)

  return (
    <section id="BannerHome" className="bg-outrageous-orange py-5 sm:py-10">
      <div className="max-w-[2000px] mx-auto">
        <div className="grid grid-cols-2 lg:hidden pb-5">
          {fields.images?.map((block, index) => {
            return (
              <Link
                key={index}
                to={block?.link?.url || '/'}
                className={clsx(`group aspect-square sm:aspect-video relative flex items-center transition-all duration-200`)}
              >
                <Content className="content xl:children-2xl 2xl:children-h3:text-4xl absolute z-20 bottom-5 sm:bottom-5 lg:bottom-5 text-shadow children-h3:text-xl md:children-h3:text-3xl children-h3:font-nord group-hover:children-h3:text-outrageous-orange children-h3:text-white children-h3:font-medium children-h3:transition-all children-h3:duration-200 lg:px-10 left-0 right-0 mx-auto">
                  {block?.description}
                </Content>
                <div className="relative z-10 h-full">
                  <LossePlaatjie
                    loading="eager"
                    maxwidth={1000}
                    className={clsx('object-cover max-h-[unset] h-full')}
                    src={block?.image}
                    alt="block-image"
                  />
                </div>
              </Link>
            )
          })}
        </div>
        <Slider
          mousewheel={{
            forceToAxis: true
          }}
          loop
          className="relative home-header lg:block hidden h-[345px] md:h-[585px]"
          autoplay={{
            delay: 5000,
            disableOnInteraction: false
          }}
          breakpoints={{
            // 0: {
            //   slidesPerView: 1,
            //   spaceBetween: 0
            // },
            0: {
              slidesPerView: 2,
              spaceBetween: 0
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 0
            }
          }}
        >
          {fields.images?.map((block, index) => {
            return (
              <Slide
                className={clsx(
                  // if active is 0
                  active === 0 && active === index && '!h-[210px] md:!h-[410px]',
                  active === 0 && active + index === 1 && '!h-[280px] md:!h-[585px]',
                  active === 0 && active + index === 2 && '!h-[210px] md:!h-[410px]',
                  active === 0 && active + index === 3 && '!h-[185px] md:!h-[287px]',
                  // if active is 1
                  active === 1 && active + index === 1 && '!h-[185px] md:!h-[287px]',
                  active === 1 && active + index === 4 && '!h-[210px] md:!h-[410px]',
                  active === 1 && active + index === 2 && '!h-[210px] md:!h-[410px]',
                  active === 1 && active + index === 3 && '!h-[280px] md:!h-[585px]',
                  // if active is 2
                  active === 2 && active === index && '!h-[210px] md:!h-[410px]',
                  active === 2 && active + index === 5 && '!h-[280px] md:!h-[585px]',
                  active === 2 && active + index === 2 && '!h-[210px] md:!h-[410px]',
                  active === 2 && active + index === 3 && '!h-[185px] md:!h-[287px]',
                  // if active is 3
                  active === 3 && active === index && '!h-[210px] md:!h-[410px]',
                  active === 3 && active + index === 5 && '!h-[185px] md:!h-[287px]',
                  active === 3 && active + index === 4 && '!h-[210px] md:!h-[410px]',
                  active === 3 && active + index === 3 && '!h-[280px] md:!h-[585px]',
                  '!transition-all duration-200'
                )}
                key={index}
              >
                {/* {`${active} + ${index} = ${active + index}`} */}

                <CustomSlide index={index} setActive={setActive} item={block} />
              </Slide>
            )
          })}
          {fields.images?.map((block, index) => {
            return (
              <Slide
                className={clsx(
                  // if active is 0
                  active === 0 && active === index && '!h-[210px] md:!h-[410px]',
                  active === 0 && active + index === 1 && '!h-[280px] md:!h-[585px]',
                  active === 0 && active + index === 2 && '!h-[210px] md:!h-[410px]',
                  active === 0 && active + index === 3 && '!h-[185px] md:!h-[287px]',
                  // if active is 1
                  active === 1 && active + index === 1 && '!h-[185px] md:!h-[287px]',
                  active === 1 && active + index === 4 && '!h-[210px] md:!h-[410px]',
                  active === 1 && active + index === 2 && '!h-[210px] md:!h-[410px]',
                  active === 1 && active + index === 3 && '!h-[280px] md:!h-[585px]',
                  // if active is 2
                  active === 2 && active === index && '!h-[210px] md:!h-[410px]',
                  active === 2 && active + index === 5 && '!h-[280px] md:!h-[585px]',
                  active === 2 && active + index === 2 && '!h-[210px] md:!h-[410px]',
                  active === 2 && active + index === 3 && '!h-[185px] md:!h-[287px]',
                  // if active is 3
                  active === 3 && active === index && '!h-[210px] md:!h-[410px]',
                  active === 3 && active + index === 5 && '!h-[185px] md:!h-[287px]',
                  active === 3 && active + index === 4 && '!h-[210px] md:!h-[410px]',
                  active === 3 && active + index === 3 && '!h-[280px] md:!h-[585px]',
                  '!transition-all duration-200'
                )}
                key={index}
              >
                {/* {`${active} + ${index} = ${active + index}`} */}

                <CustomSlide index={index} setActive={setActive} item={block} />
              </Slide>
            )
          })}
        </Slider>
        <div className="container">
          <div className="max-w-screen-xl mx-auto">
            <div className="flex flex-col gap-5 sm:gap-10 justify-center py-5d sm:py-10 md:pt-14 md:pb-24">
              <Content className="content-xs">{fields.description}</Content>
              <div className="flex gap-6 justify-center">
                {fields.links?.map((link, index) => (
                  <Link key={index} className={clsx(index === 0 && 'button', index === 1 && 'button-white')} to={link?.link?.url || '/'}>
                    {link?.link?.title}
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

function CustomSlide({
  item,
  setActive,
  index
}: {
  item: Maybe<Page_Flexcontent_Flex_Banner_Images>
  setActive: (index: number) => void
  index: number
}) {
  const slide = useSwiperSlide()

  useEffect(() => {
    if (slide.isActive) {
      setActive(index)
    }
  }, [index, setActive, slide.isActive])

  return (
    <Link
      to={item?.link?.url || '/'}
      className={clsx(
        // !slide.isActive && slide.isNext && 'h-[120%]',
        // !slide.isActive && slide.isPrev && 'h-[80%]',
        // !slide.isActive && !slide.isNext && !slide.isPrev && 'h-[100%]',
        // slide.isActive && 'h-[100%]',
        `group relative flex h-full items-center transition-all duration-200`
      )}
    >
      <Content
        className={clsx(
          !slide.isActive && slide.isNext && 'xl:children-h3:text-2xl 2xl:children-h3:text-4xl',
          !slide.isActive && slide.isPrev && 'xl:children-h3:text-2xl 2xl:children-h3:text-3xl',
          !slide.isActive && !slide.isNext && !slide.isPrev && 'xl:children-h3:text-2xl 2xl:children-h3:text-4xl',
          slide.isActive && 'xl:children-h3:text-2xl 2xl:children-h3:text-4xl',
          'absolute z-20 bottom-5 sm:bottom-20 lg:bottom-20 text-shadow children-h3:text-base sm:children-h3:text-xl md:children-h3:text-2xl children-h3:font-nord group-hover:children-h3:text-outrageous-orange children-h3:text-white children-h3:font-medium children-h3:transition-all children-h3:duration-200 lg:px-8 left-0 right-0 mx-auto'
        )}
      >
        {item?.description}
      </Content>
      <div className="relative z-10 h-full">
        <LossePlaatjie
          loading="eager"
          maxwidth={1000}
          className={clsx('object-cover max-h-[unset] h-full')}
          src={item?.image}
          alt="block-image"
        />
      </div>
    </Link>
  )
}
