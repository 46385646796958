import { useState } from 'react'
import Content from './Content'
import type { Page_Flexcontent_Flex_Misc } from '~/graphql/types'
import clsx from 'clsx'

export default function NewsletterPopup({ fields }: { fields: Page_Flexcontent_Flex_Misc }) {
  const [open, setOpen] = useState(false)

  return (
    <section
      id="NewsletterPopup"
      className={clsx(
        open ? 'bottom-0' : 'bottom-[180px]',
        'fixed right-0 pointer-events-none top-0 ml-auto my-auto z-[100] sm:flex hidden items-center'
      )}
    >
      <div className="bg-sprout max-w-[350px] pointer-events-auto rounded-l-[20px] relative">
        <button
          onClick={() => setOpen(!open)}
          className={clsx(
            'absolute bg-sprout rounded-t-[20px] text-outrageous-orange top-[40px] w-[214px] -left-[246px] h-[64px] text-xl origin-right -rotate-90 flex justify-center items-center gap-x-2 font-bold'
          )}
          type="button"
        >
          <svg className="rotate-0" width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="17.5" cy="17.5" r="17.5" fill="#FF5C3A" />
            <g clipPath="url(#clip0_992_39)">
              <path
                d="M9.58333 24.625V10.375C9.58333 10.165 9.66674 9.96367 9.81521 9.81521C9.96367 9.66674 10.165 9.58333 10.375 9.58333H14.3333V8H10.375C9.74511 8 9.14102 8.25022 8.69562 8.69562C8.25022 9.14102 8 9.74511 8 10.375V24.625C8 25.2549 8.25022 25.859 8.69562 26.3044C9.14102 26.7498 9.74511 27 10.375 27H14.3333V25.4167H10.375C10.165 25.4167 9.96367 25.3333 9.81521 25.1848C9.66674 25.0363 9.58333 24.835 9.58333 24.625V24.625Z"
                fill="#D0DEBB"
              />
              <path
                d="M26.9998 18.2912V16.7079L13.9816 16.7324L17.404 13.3092L16.283 12.1898L12.6524 15.8204C12.2072 16.2658 11.957 16.8698 11.957 17.4995C11.957 18.1293 12.2072 18.7333 12.6524 19.1787L16.283 22.8092L17.4024 21.6898L14.0275 18.3149L26.9998 18.2912Z"
                fill="#D0DEBB"
              />
            </g>
            <defs>
              <clipPath id="clip0_992_39">
                <rect width="19" height="19" fill="white" transform="translate(8 8)" />
              </clipPath>
            </defs>
          </svg>

          <span>{fields.buttontext}</span>
        </button>
        {open && (
          <div className="px-9 py-6 min-h-[300px] flex flex-col gap-4">
            <Content className="content-sm">{fields.description}</Content>
            <div className="flex flex-col items-start gap-3">
              <a href={fields.apple?.url || '/'}>
                <img src="/images/apple.png" alt="apple-app-store-button" />
              </a>
              <a href={fields.android?.url || '/'}>
                <img src="/images/android.png" alt="android-app-store-button" />
              </a>
            </div>
          </div>
        )}
      </div>
    </section>
  )
}
