import { useSearchParams } from '@ubo/losse-sjedel'
import * as yup from 'yup'
import { AnimatePresence, motion } from 'framer-motion'

const YupPostcode = yup.string().matches(/^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i, 'Postcode is incorrect')

export default function PostcodeFilter() {
  const [searchParams, setSearchParams] = useSearchParams()

  function handleFilter(type: string, e: React.ChangeEvent<HTMLSelectElement> | React.ChangeEvent<HTMLInputElement>) {
    if (e.currentTarget.value === '') {
      searchParams.delete(type)
    } else {
      searchParams.set(type, e.currentTarget.value)
    }

    setSearchParams(searchParams)
  }

  async function handlePostcode(type: string, e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.value === '' || e.target.value === null || e.target.value === undefined) {
      searchParams.delete(type)
    } else {
      YupPostcode.validate(e.target.value)
        .then((r) => {
          searchParams.set(type, r || e.target.value)
          setSearchParams(searchParams)
        })
        .catch(() => {
          if (searchParams.get(type)) {
            searchParams.delete(type)
            setSearchParams(searchParams)
          }
        })
    }
  }
  return (
    <section id="PostcodeFilter">
      <label className="block capitalize font-prompt text-base md:text-xl font-light pb-1 md:pb-3" htmlFor="postcode">
        Afstand
      </label>
      <input
        className="h-[51px] md:h-[59px] mb-3 bg-white rounded-none placeholder:text-silver relative font-prompt text-base md:text-lg cursor-pointer font-light w-full px-4 2xl:px-6 block appearance-none focus:ring-2 focus-visible:outline-none focus:ring-offset-2 focus:ring-offset-white focus:ring-outrageous-orange"
        name="postcode"
        id="postcode"
        required
        placeholder="Voer hier jouw postcode in"
        onChange={(e) => handlePostcode('postcode', e)}
        defaultValue={searchParams.get('postcode') || ''}
      />
      <AnimatePresence>
        {searchParams.get('postcode') && (
          <motion.div initial={{ height: 0 }} animate={{ height: 'fit-content' }} exit={{ height: 0 }} className="relative">
            <svg
              className="absolute z-10 top-0 bottom-0 right-6 my-auto"
              width="26"
              height="17"
              viewBox="0 0 26 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M2 2L13 13L24 2" stroke="#FF5C39" strokeWidth="5" />
            </svg>
            <select
              className="h-[59px] bg-white invalid:text-silver relative font-prompt text-lg cursor-pointer font-light w-full px-6 block appearance-none focus:ring-2 focus-visible:outline-none focus:ring-offset-2 focus:ring-offset-white focus:ring-outrageous-orange"
              name="afstand"
              id="afstand"
              required
              onChange={(e) => handleFilter('afstand', e)}
              value={searchParams.get('afstand') || '10'}
            >
              <option value="5">5 km</option>
              <option value="10">10 km</option>
              <option value="15">15 km</option>
              <option value="20">20 km</option>
              <option value="30">30 km</option>
              <option value="40">40 km</option>
              <option value="50">50 km</option>
            </select>
          </motion.div>
        )}
      </AnimatePresence>
    </section>
  )
}
