import { LosseLink } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Text } from '~/graphql/types'

export default function TextDefault({ fields }: { fields: Page_Flexcontent_Flex_Text }) {
  return (
    <section id="TextDefault">
      <div className="container section lg:max-w-screen-lg">
        <div className="flex flex-col gap-6 lg:gap-10">
          <Content className="content lg:text-center">{fields?.description}</Content>
          {(fields.links?.length || 0) >= 1 && (
            <div className="flex xl:flex-row flex-col gap-3 lg:items-center items-center justify-center">
              {fields.links?.map((item, index) => (
                <LosseLink src={item?.link} key={index} className={clsx(index === 0 && 'button-alt min-w-fit', index === 1 && 'button')} />
              ))}
            </div>
          )}
        </div>
      </div>
    </section>
  )
}
