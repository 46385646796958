import type { Page_Flexcontent_Flex_Banner } from '~/graphql/types'
import { motion } from 'framer-motion'
import { lazy, Suspense, useRef } from 'react'
import { ClientOnly } from 'remix-utils'
import { useMatches } from '@ubo/losse-sjedel'

// @ts-ignore
const Video = lazy(() => import('~/components/elements/Video.client'))

export default function BannerVideo({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  const element = useRef<HTMLDivElement>(null)

  const matches = useMatches()
  const lighthouse = matches[0].data.isLighthouse

  return (
    <section
      id="BannerVideo"
      ref={element}
      // className="bg-outrageous-orange h-[calc(100vh_-_90px)] lg:h-[calc(100vh_-_140px)] 2xl:h-[calc(100vh_-_150px)] relative pt-10 pb-32 lg:pt-12 lg:pb-40"
      className="bg-outrageous-orange h-[calc(100vh_-_90px)] lg:h-full relative pt-10 pb-32 lg:pt-12 lg:pb-40"
    >
      <div className="container relative h-full">
        <h1 className="hidden">Wij zijn Yesway, jouw werk jouw leven</h1>
        <div className="bg-outrageous-orange top-1/2 -translate-y-1/2 lg:-translate-y-0 relative aspect-video max-w-full w-[auto] m-auto lg:h-full">
          {!lighthouse ? (
            <ClientOnly fallback={<></>}>
              {() => (
                <Suspense fallback={<></>}>
                  <Video fields={fields} />
                </Suspense>
              )}
            </ClientOnly>
          ) : (
            <img
              src="https://i.vimeocdn.com/video/1444269044-a0bf6216eed73079032054eaf11d91465d312e2cfaf51e87048c4f0128fc70cf-d?mw=400&mh=250&q=75&format=webp"
              loading="eager"
              alt="yesway video"
            />
          )}
        </div>
        <motion.svg
          className="absolute cursor-pointer mx-auto left-0 right-0 -bottom-20 lg:-bottom-24"
          width="39"
          height="52"
          viewBox="0 0 39 52"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          animate={{ y: [-20, 20, -20] }}
          transition={{ loop: Infinity, duration: 4 }}
          onClick={() => {
            if (!element.current) {
              return
            }

            const top = element.current.clientHeight

            if (top - window.scrollY <= 0) {
              return
            }

            window.scrollTo({
              top,
              left: 0,
              behavior: 'smooth'
            })
          }}
        >
          <g clipPath="url(#clip0_141_34)">
            <path
              d="M19.5006 9.93723C18.1546 9.93723 17.0635 11.0448 17.0635 12.4111V17.36C17.0635 18.7263 18.1546 19.8339 19.5006 19.8339C20.8466 19.8339 21.9378 18.7263 21.9378 17.36V12.4111C21.9378 11.0448 20.8466 9.93723 19.5006 9.93723Z"
              fill="white"
            />
            <path
              d="M21.9377 0.0405933V0H17.0623V0.0405933C7.64392 0.0510316 0.0102833 7.79855 0 17.36V32.2056C0 43.1379 8.73053 52 19.5006 52C30.2706 52 39.0011 43.1379 39.0011 32.2056V17.36C38.9897 7.79855 31.3572 0.0510316 21.9377 0.0405933ZM34.1257 32.2056C34.1257 40.4042 27.5775 47.0511 19.5006 47.0511C11.4236 47.0511 4.87543 40.4042 4.87543 32.2056V17.36C4.88343 10.5311 10.3359 4.99645 17.0634 4.98833H21.9389C28.6664 4.99645 34.1189 10.5311 34.1269 17.36V32.2056H34.1257Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_141_34">
              <rect width="39" height="52" fill="white" />
            </clipPath>
          </defs>
        </motion.svg>
      </div>
    </section>
  )
}
