import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Banner } from '~/graphql/types'

export default function BannerDefault({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  return (
    <section id="BannerDefault" className="bg-outrageous-orange">
      <div className="container">
        <div className="pb-8 pt-14 md:pb-8 md:pt-16 lg:pb-14 lg:pt-24">
          <Content className="content children-h1:text-white">{fields?.description}</Content>
        </div>
      </div>
    </section>
  )
}
