import type { Page_Flexcontent_Flex_Cta } from '~/graphql/types'
import { LosseContent as Content } from '@ubo/losse-sjedel'
import { LossePlaatjie as Image } from '@ubo/losse-sjedel'
import { LosseLink as Link } from '@ubo/losse-sjedel'

export default function ThreeBlocks({ fields }: { fields: Page_Flexcontent_Flex_Cta }) {
  return (
    <section id="ThreeBlocks" className="py-10 md:py-16 lg:py-24">
      <div className="container">
        <Content className="children-h2:leading-normal">{fields.description}</Content>
        <div className="grid grid-cols-7 xl:px-20 py-5 md:py-10 lg:py-14">
          {fields.blocks?.map((block, index) => (
            <Link
              to={block?.link?.uri}
              key={index}
              className={`${index === 1 ? `col-span-3` : `col-span-2`} group relative flex items-center`}
            >
              <Content className="absolute z-20 bottom-10 sm:bottom-20 lg:bottom-36 text-shadow children-h3:text-lg sm:children-h3:text-xl md:children-h3:text-3xl lg:children-h3:text-6xl children-h3:font-nord group-hover:children-h3:text-outrageous-orange children-h3:text-white children-h3:font-medium children-h3:transition-all children-h3:duration-200 left-0 right-0 mx-auto">
                {block?.description}
              </Content>
              <div className="relative z-10 aspect-square">
                {index !== 1 && (
                  <div
                    className={`absolute h-full ${
                      index === 0 ? `right-0 bg-gradient-to-l` : `left-0 bg-gradient-to-r`
                    } top-0 w-[90px] from-mine-shaft to-transparent`}
                  />
                )}
                <Image
                  maxwidth={index === 1 ? 700 : 400}
                  className="aspect-square object-cover h-full"
                  src={block?.image}
                  alt="block-image"
                />
              </div>
            </Link>
          ))}
        </div>
        <div className="flex justify-center">
          <Link className="button-alt" to={fields.link?.url}>
            {fields.link?.title}
          </Link>
        </div>
      </div>
    </section>
  )
}
