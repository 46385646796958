import Slider from 'react-slick'
import type { Page_Flexcontent_Flex_Misc } from '~/graphql/types'
import { LosseContent as Content } from '@ubo/losse-sjedel'
import { useState, useEffect } from 'react'

export default function WordWheel({ fields }: { fields: Page_Flexcontent_Flex_Misc }) {
  const [doubleWords, setDoubleWords] = useState(fields.words)
  const [currentIndex, setCurrentIndex] = useState(0)

  useEffect(() => {
    setDoubleWords(doubleWords?.concat(fields.words || []))
    // eslint-disable-next-line
  }, [])

  return (
    <section id="WordWheel">
      <div className="container py-10 md:py-16 lg:py-24">
        <div className="grid grid-cols-11 w-full gap-3 lg:gap-16">
          <div className="col-span-5 sm:col-span-5 relative flex justify-end items-center">
            <Content className="text-outrageous-orange children-p:text-right children-p:font-nord children-p:text-lg md:children-p:text-3xl lg:children-p:text-5xl children-p:font-medium children-p:mb-0">
              {fields.description}
            </Content>
          </div>
          <div className="col-span-1 flex justify-center">
            <div className="bg-mine-shaft w-[1px] h-full" />
          </div>
          <div className="col-span-5 sm:col-span-5">
            <Slider
              afterChange={(current) => {
                setCurrentIndex(current)
              }}
              arrows={false}
              speed={500}
              autoplay
              autoplaySpeed={2000}
              vertical
              verticalSwiping
              slidesToShow={3}
              infinite
              slidesToScroll={1}
            >
              {doubleWords?.map((item, index) => (
                <div
                  className={`text-lg md:text-3xl lg:text-5xl transition-all duration-200 ${
                    index === (currentIndex + 1 === doubleWords.length ? -1 : currentIndex) + 1 ? `` : `text-opacity-30`
                  } text-mine-shaft font-nord font-medium !leading-loose`}
                  key={index}
                >
                  {item?.word}
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  )
}
