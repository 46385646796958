// import { useLoaderData, type LoaderData } from '@ubo/losse-sjedel'
import { useNavigate } from '@ubo/losse-sjedel'
import { useEffect } from 'react'
// import type { Component_Kennismaken } from '~/graphql/types'

// export interface FooterLoaderData extends Omit<LoaderData, 'kennismaken'> {
//   kennismaken: Component_Kennismaken
// }

export default function Kennismaken() {
  const navigate = useNavigate()

  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js'
    script.async = true
    document.body.appendChild(script)
  }, [])

  return (
    <div
      onClick={() => {
        navigate('#')
      }}
      className="fixed z-[200] cursor-pointer h-full w-full bg-white flex justify-center items-center"
    >
      <div className="absolute top-2 md:top-4 right-2 md:right-4 flex items-center">
        <span className="mr-3">Terug naar de pagina</span>
        <svg
          onClick={() => {
            navigate('#')
          }}
          width="24"
          height="24"
          viewBox="0 0 13 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="fi-bs-cross" clip-path="url(#clip0_1580_502)">
            <path
              id="Vector"
              d="M13.0329 1.11634L11.8835 -0.0330811L6.49984 5.35109L1.11621 -0.0330811L-0.0332031 1.11634L5.35096 6.49996L-0.0332031 11.8836L1.11621 13.033L6.49984 7.64884L11.8835 13.033L13.0329 11.8836L7.64871 6.49996L13.0329 1.11634Z"
              fill="#FF5C39"
            />
          </g>
          <defs>
            <clipPath id="clip0_1580_502">
              <rect width="13" height="13" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>

      <div
        onClick={() => {
          navigate('#')
        }}
        className="container flex justify-center items-center cursor-pointer"
      >
        <div className="w-[900px]">
          <div
            onClick={(e) => e.stopPropagation()}
            className="meetings-iframe-container"
            data-src="https://meetings-eu1.hubspot.com/blokhuis?embed=true"
          />
        </div>
      </div>
    </div>
  )
}
