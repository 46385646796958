import React from 'react'
import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'

// Components
import FormContact from './FormContact'
import Apply from './Apply'
import FormEvent from './FormEvent'
import FormAltContact from './FormAltContact'
import Brochure from './Brochure'

const FormShell: React.FC<LosseFlexShellProps> = ({ fields, index }) => {
  const types: LosseFlexShellType = {
    contact: FormContact,
    altcontact: FormAltContact,
    apply: Apply,
    event: FormEvent,
    brochure: Brochure
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}

export default FormShell
